import { IconProps } from "../../types/icon";

export const CRMIcon: React.FC<IconProps> = ({ ...props }) => {
    return (
      <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className="ant-menu-item-icon"
      {...props}
    >
      <g>
        <path fill="none" d="M0 0h14v14H0z" />
        <path d="M12.833 9.918a3.501 3.501 0 0 1 -2.75 3.418l-0.372 -1.116A2.335 2.335 0 0 0 11.355 11.083H9.917a1.167 1.167 0 0 1 -1.167 -1.167v-2.333a1.167 1.167 0 0 1 1.167 -1.167h1.714a4.667 4.667 0 0 0 -9.261 0H4.083a1.167 1.167 0 0 1 1.167 1.167v2.333a1.167 1.167 0 0 1 -1.167 1.167H2.333a1.167 1.167 0 0 1 -1.167 -1.167v-2.917C1.167 3.779 3.779 1.167 7 1.167s5.833 2.612 5.833 5.833V9.918z" />
      </g>
    </svg>
        );
};
