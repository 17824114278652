import { IconProps } from "../../types/icon";

export const BasicInfoIcon: React.FC<IconProps> = ({ ...props }) => {
    return (
        <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        className="ant-menu-item-icon"
        {...props}
      >
        <defs>
          <style>{".cls-1{fill:#04009a;}.cls-2{fill:#77acf1;}"}</style>
        </defs>
        <g data-name="7. Id Card" id="_7._Id_Card">
          <path
            className="cls-1"
            d="M12.688 3.937H10.5a0.438 0.438 0 0 0 0 0.875h2.188a0.438 0.438 0 0 1 0.438 0.438V12.25a0.438 0.438 0 0 1 -0.438 0.438H1.313a0.438 0.438 0 0 1 -0.438 -0.438V5.25a0.438 0.438 0 0 1 0.438 -0.438h3.5a0.875 0.875 0 0 0 0.875 0.875h2.625a0.875 0.875 0 0 0 0.875 -0.875V1.313a0.875 0.875 0 0 0 -0.875 -0.875H5.688a0.875 0.875 0 0 0 -0.875 0.875V3.937H1.313a1.313 1.313 0 0 0 -1.313 1.313V12.25a1.313 1.313 0 0 0 1.313 1.313H12.688a1.313 1.313 0 0 0 1.313 -1.313V5.25A1.313 1.313 0 0 0 12.688 3.937ZM5.688 1.313h2.625v3.5H5.688Z"
          />
          <path
            className="cls-1"
            d="M1.75 11.375a0.438 0.438 0 0 0 0.438 0.438h3.5a0.438 0.438 0 0 0 0.438 -0.438 2.188 2.188 0 0 0 -0.965 -1.814 1.75 1.75 0 1 0 -2.444 0A2.188 2.188 0 0 0 1.75 11.375Zm1.313 -3.063a0.875 0.875 0 1 1 0.875 0.875A0.875 0.875 0 0 1 3.063 8.313Zm0.875 1.75a1.315 1.315 0 0 1 1.238 0.875H2.7A1.315 1.315 0 0 1 3.937 10.063Z"
          />
          <path
            className="cls-2"
            d="M10.938 10.938H8.313a0.438 0.438 0 0 1 0 -0.875h2.625a0.438 0.438 0 0 1 0 0.875Z"
          />
          <path
            className="cls-2"
            d="M11.813 9.188H8.313a0.438 0.438 0 0 1 0 -0.875h3.5a0.438 0.438 0 0 1 0 0.875Z"
          />
          <path
            className="cls-2"
            d="M11.813 7.437H8.313a0.438 0.438 0 0 1 0 -0.875h3.5a0.438 0.438 0 0 1 0 0.875Z"
          />
          <path
            className="cls-2"
            d="M7.008 3.937a0.438 0.438 0 0 1 -0.004 -0.875h0.004a0.438 0.438 0 0 1 0 0.875Z"
          />
        </g>
      </svg>
        );
};
