//{"sub":"634e902263ff925de2d7fd94","scopes":[{"authority":"ADMIN"},{"authority":"USER"},{"authority":"COMPANY_ID_63472dff1ee57862774ded84"}],"iss":"https://www.benam.me","iat":1666176779,"exp":1666194779}
const getLoggedInUserId = () => {
  return localStorage.getItem("userId");
};
const clearAll = () => {
  return localStorage.clear();
};
const getLoggedInUserRoles = () => {
  if (localStorage.getItem("roles"))
    return JSON.parse(localStorage.getItem("roles") || "{}");
};
const isSuperAdmin = () => {
  if (localStorage.getItem("roles")) {
    const roles: Array<string> = JSON.parse(
      localStorage.getItem("roles") || "{}"
    );
    return roles.includes("ADMIN");
  }
  return false;
};

const isMarketPlaceAdmin = () => {
  if (localStorage.getItem("roles")) {
    const roles: Array<string> = JSON.parse(
      localStorage.getItem("roles") || "{}"
    );
    return roles.includes("MARKETPLACE_ADMIN");
  }
  return false;
};

const isCompanyAdmin = () => {
  if (localStorage.getItem("roles")) {
    const roles: Array<string> = JSON.parse(
      localStorage.getItem("roles") || "{}"
    );
    return roles.includes("COMPANY_ADMIN");
  }
  return false;
};
const isCompanyUser = () => {
  if (localStorage.getItem("roles")) {
    const roles: Array<string> = JSON.parse(
      localStorage.getItem("roles") || "{}"
    );
    return roles.includes("COMPANY_USER");
  }
  return false;
};
const getLoggedInUserCompanyId = () => {
  return localStorage.getItem("companyId");
};

const getLoggedInUserCompleteName = () => {
  return JSON.parse(localStorage.getItem("user")!).name;
};

const setScope = (profile: any) => {
  localStorage.setItem("userId", `${profile.sub}`);
  if (profile.scopes) {
    localStorage.setItem("scopes", JSON.stringify(profile.scopes));
    const roles = Array<string>();
    profile.scopes.forEach((ele: { authority: string }) => {
      if (ele.authority) {
        const auth = ele.authority as string;
        if (auth.includes("COMPANY_ID")) {
          localStorage.setItem(
            "companyId",
            `${auth?.replace("COMPANY_ID_", "")}`
          );
        } else {
          roles.push(auth);
        }
      }
    });
    localStorage.setItem("roles", JSON.stringify(roles));
  }
};

export default {
  getLoggedInUserId,
  getLoggedInUserRoles,
  getLoggedInUserCompanyId,
  getLoggedInUserCompleteName,
  setScope,
  isSuperAdmin,
  isMarketPlaceAdmin,
  isCompanyUser,
  isCompanyAdmin,
  clearAll,
};
