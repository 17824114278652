import { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Button, DatePicker, Select } from "@pankod/refine-antd";
import dayjs from "dayjs";
import { Lead } from "interfaces";
import allUsedCarsService from "services/allusedCarsService";
import authService from "services/authService";
import companyService from "services/companyService";
import datetimeHelper from "utils/dateTimeHelper";
import masterData from "utils/masterData";
import AddCommentPopup from "./AddComment";
interface LeadProps {
  slead: Lead|null;
  userOptions: any;
  callback: any;
  show: boolean;
}

const LeadAssignToPopup = (props: LeadProps) => {
  var { callback, show, userOptions, slead } = props;

  const [showin, setShow] = useState<boolean>(false);
  const [user, setUser] = useState<any>(userOptions[0]?.alias);

  useEffect(() => {
    setShow(show);
  }, []);

  useEffect(() => {
    setShow(show);
  }, [show]);

  function handleCancel(event: any) {
    callback();
    setShow(false);
  }
  async function handleCallback() {
    const [lead, status] =  await allUsedCarsService.assignUserToLead(
      slead!.leadId!,
      userOptions.find((u: any) => u.alias === user)?.userId,
      user
    );
    if (status == 200) {
      if (lead != null) {
        if (callback) {
           callback(lead);
          setShow(false);
        }
      }
    }
   
  }

  return (
    <>
      {" "}
      {showin && <div className="backdrop" onClick={handleCancel} />}
      <div
        className="filterspopupWrp"
        style={{ display: showin ? undefined : "none" }}
      >
        <div className="filtersScrollArea" style={{ height: "auto" }}>
          <div className="seperate-filter-wrp">
            <h4>Assigned To:</h4>
            <Select
              style={{ width: "100%" }}
              value={user}
              onChange={(val) => {
                setUser(val);
              }}
              defaultValue={userOptions[0]?.alias}
              options={userOptions.map((u: any) => {
                return { value: u.alias, label: u.alias };
              })}
            />
          </div>
        </div>
        <Button
          className="filtersApplybtn"
          onClick={() => {
            handleCallback();
          }}
        >
          Submit
        </Button>{" "}
        <Button className="filtersApplybtn" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default LeadAssignToPopup;
