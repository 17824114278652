import { useEffect, useState,useRef } from "react";
//@ts-ignore
import Switch from "react-switch"
import {
    Button,
    Icons,
    Select
} from "@pankod/refine-antd";
import companyService from "services/companyService";
import { v4 as uuidv4 } from 'uuid';
import { notifySuccess } from "./Notification";

const AttachmentPopup = ({sendImage,userId,showPayment,setShowPayment,companyId,attachmentInfo}:{sendImage:any,showPayment:boolean,setShowPayment:any,companyId:string,userId:string,attachmentInfo:any})=>{
    const {CloseOutlined} = Icons
    const imgUrl = URL.createObjectURL(attachmentInfo)

    const uploadImage =async ()=>{
        const [attachmentUrl, status] = await companyService.imageUploadService(attachmentInfo)
        sendImage(attachmentUrl,"ATTACHMENT")
    }
    return (
        <div className="initiatepaymentpopupWrp attachmentPopup" style={{display:showPayment ? "block":"none"}} >
            <div className="closeicon" >
                <CloseOutlined onClick={()=>setShowPayment(false)} />
            </div>
            <div>
                <h1>Preview</h1>
                <img className="attachFile"  src={imgUrl} />
            </div>
            <Button onClick={()=>uploadImage()} style={{width:"100%"}} className="init_payment_btn" >Send</Button>
        </div>
    )
}

export default AttachmentPopup