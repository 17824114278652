const defaultConfig: AppConfigurations = {
  debug: false,
  urls: {
    serviceBaseUrl: "https://benam-core-piilyr264a-el.a.run.app/",
    loginVerifyUrl: "api/public/login-success?token=",
    getCompanyInfo: "api/internal/companies/",
    getLinkedUsersUrl: "api/internal/companies/{0}/users",
    getCompanies: "api/public/companies",
    getCompaniesInternal: "api/internal/companies",
    getUserCompaniesAdmin: "api/internal/companiesusers",
    getUserMsgUrl: "api/internal/companies/{0}/users/{1}/messages",
    sendUserMsgUrl: "api/internal/companies/{0}/users/{1}/messages/send",
    getCategories: "api/public/categories",
    getSubCategories: "api/public/subcats/?categoryId=",
    changeChatStatus: "api/internal/userCompanies/",
    imageUpload: "api/public/images/upload",
    requestCallback: "api/internal/userCompanies",
    ws: "wss://uwmxmafdl7.execute-api.ap-south-1.amazonaws.com/Prod",
    assignAgent: "api/internal/admin/users/email",
    assignAgentPhone: "api/internal/admin/users/phone",
    generateMagicLink: "api/public/generateMagicLink",
    getMagicLinkStatus: "api/public/getMagicLinkStatus",
    initPayment: "api/internal/initiatePayments/companies",
    getPosts: "api/public/posts",
    approvePost: "api/internal/posts",
    generateOtp: "api/public/generateOTP",
    resendOtp: "api/public/resend",
    verifyOtp: "api/public/verifyOtp",
    getBookings: "api/internal/companies/bookings",
    getProducts: "api/internal/products",
    getProduct: "api/internal/products/{0}",
    saveProduct: "api/internal/companies/{0}/products?isBasic=true",
    updateProductStatus: "api/internal/companies/{0}/products/{1}/approve",
    allusedCarsBaseUrl: "https://allusedcars-crawler-piilyr264a-el.a.run.app/",
    getLeads: "api/internal/leads/v3",
    updateLead: "api/internal/leads/",
    sendCustomerMsg: "api/internal/leads/{0}/messages",
    saveLead: "api/public/leads",
    createPaymentRequest: "api/internal/createPaymentRequest",
    getAssignToUsers: "api/internal/companies/",
  },
  minimized: false,
  googleClientId:
    "993103102629-npdubkcudbu7ig7pslcjp0vpnfoc2rqp.apps.googleusercontent.com",
  disableDarkMode: false,
};

export { defaultConfig };
export interface AppConfigurations {
  debug: boolean;
  urls: Url;
  googleClientId: string;
  minimized: boolean;
  disableDarkMode: boolean;
}
export type Url = {
  [key: string]: string;
};
