
import companyService from "services/companyService";
import {
    Typography,
    Button,
    Show,
    Space,
    MarkdownField,
    EditButton,
} from "@pankod/refine-antd";

import { ICompany } from "interfaces";
import { useEffect, useState } from "react";

const { Title, Text } = Typography;

export const CompanyInfo: React.FC = () => {

    const [company, setCompany] = useState<ICompany>();
    const [isLoading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        async function getcompanyInfo() {
            const [company,status] = await companyService.getCompanyInfo();
            if(status ==200)
                setCompany(company as ICompany);
                setLoading(false)
        }
        getcompanyInfo();
     }, [])




    return (
        <Show
            title="Company Information"
            isLoading={isLoading}
            canEdit={true}
            recordItemId={company?.companyId}
            canDelete={false}
            headerButtons={<EditButton recordItemId={company?.companyId}/>}
            pageHeaderProps={{
                backIcon: true,
            }}
            actionButtons={
                <Space
                    key="action-buttons"
                    style={{ float: "right", marginRight: 24 }}
                >
                   
                </Space>
            }
        >
             <Title level={5}>CompanyId</Title>
            <Text>{company?.companyId}</Text>
            <Title level={5}>Name</Title>
            <Text>{company?.companyName}</Text>

            <Title level={5}>Status</Title>
            <Text>{company?.companyStatus}</Text>

            <Title level={5}>Logo</Title>
            <Text>{company?.logo}</Text>

            <Title level={5}>Main Image</Title>
            <Text>{company?.logo}</Text>

            <Title level={5}>Plan</Title>
            <Text>{company?.plan}</Text>

            <Title level={5}>Phone</Title>
            <Text>{company?.phoneNumber}</Text>

            <Title level={5}>Email</Title>
            <Text>{company?.email}</Text>
            <Title level={5}>Website</Title>
            <Text>{company?.domain}</Text>
            <Title level={5}>Address</Title>
            <MarkdownField value={company?.address} />
            <Title level={5}>DealerId</Title>
            <MarkdownField value={company?.externalReferenceId} />

            <Title level={5}>dealerUrl</Title>
            <MarkdownField value={`https://www.allusedcars.in/dealers/${company?.externalReferencePath}`} />
           
            <Title level={5}>product Source</Title>
            <MarkdownField value={company?.productSource} />
           
            <Title level={5}>Description</Title>
            <MarkdownField value={company?.description} />
            <Title level={5}>Service Cost</Title>
            <MarkdownField value={company?.serviceCostText} />
           
            <Title level={5}>catalogueId</Title>
            <MarkdownField value={company?.catalogueId} />    

            <Title level={5}>Main Image</Title>
            <MarkdownField value={company?.mainImage} />
      
            <Title level={5}>Category</Title>
            <Text>{company?.category}</Text>
            <Title level={5}>Sub-Category</Title>
            <Text>{company?.subCategory}</Text>
 
           

        </Show>
    );
};
