import { faClipboard, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const ClipboardModal = ({
  value,
  show,
  onClose,
}: {
  value: string;
  show: boolean;
  onClose: () => void;
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const link = "https://www.allusedcars.in/pay?r=" + value;
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  return (
    <>
      {show && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close"
              onClick={() => {
                setCopied(false);
                onClose();
              }}
            >
              &times;
            </span>
            <div>
              <text>Payment URL Generated!!</text>
              <div className="link-container">
                <a href={link}>{link}</a>
                <FontAwesomeIcon
                  icon={faCopy}
                  size="lg"
                  onClick={copyToClipboard}
                  style={{ cursor: "pointer" }}
                />
                {copied ? <text>Copied!</text> : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClipboardModal;
