import { useEffect, useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Show, Typography, Avatar } from "@pankod/refine-antd";
import { useNavigate } from "react-router-dom";
import companyService from "services/companyService";
const { Title, Text } = Typography;
import Autosuggest from "react-autosuggest";
import { notifySuccess } from "../../components/Notification";
import { ToastContainer, toast } from "react-toastify";
import dateFormat from "dateformat";
import authService from "services/authService";
export const Bookings: React.FC<IResourceComponentsProps> = () => {
  const [allorders, setAllOrders] = useState([]);

  const getAllBookings = async () => {
    const [response, status] = await companyService.getAllBookings(
      authService.getLoggedInUserCompanyId()
    );
    console.log(response);
    setAllOrders(response);
  };

  useEffect(() => {
    getAllBookings();
  }, []);

  return (
    <Show
      title="My Bookings"
      // isLoading={isLoading}
      headerButtons={() => <></>}
    >
      <ToastContainer />
      <div>
        {allorders && allorders.length > 0 ? (
          allorders.map((item: any) => (
            <div className={"allOrdersWrp"} style={{ alignItems: "end" }}>
              <div className="orderInfo" style={{ display: "flex" }}>
                <Avatar style={{ marginRight: "2%" }} src={item.company.logo} />
                <div>
                  <p>
                    Company Name : <span>{item.company.companyName}</span>
                  </p>
                  <p>
                    Category : <span>{item.company.category}</span>
                  </p>
                  <p>
                    OrderId : <span>{item.orderId}</span>
                  </p>
                  <p>
                    UserId : <span>{item.userId}</span>
                  </p>
                  <p>
                    Booking Time :{" "}
                    <span>
                      {dateFormat(
                        new Date(item.bookingDateTime! * 1000),
                        "dd-mmm hh:MM TT"
                      )}
                    </span>
                  </p>
                  {item.service?.title.length > 0 && (
                    <p>
                      Description: <span>{item.service?.title}</span>
                    </p>
                  )}
                </div>
              </div>
              <div className="paymentWrp" style={{ padding: "0" }}>
                {/* <div className="payamnt">
                  <img width="10px" src="/rupee.svg" />
                  {item.amount / 100}
                </div> */}
                {item.status === "PAYMENT_PENDING" ? (
                  <div className="payinitbtn">
                    <p>Payment Pending</p>
                    {/* <ReloadOutlined className="reloadicon" onClick={()=>getOrderByOrderId(item.metadata.order.orderId,item.messageId)} /> */}
                  </div>
                ) : (
                  <div className="paysuccessbtn">
                    <p>Approved</p>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>
            <h1>No orders placed yet.</h1>
          </div>
        )}
      </div>
    </Show>
  );
};
