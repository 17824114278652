import { IconProps } from "../../types/icon";

export const ChatIcon: React.FC<IconProps> = ({ ...props }) => {
    return (
        <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        {...props}
        >
        <path
          fill="none"
          stroke="#000"
          strokeWidth={1.1666666666666667}
          d="M5.25 4.083L5.25 0.583L13.417 0.583L13.417 6.417L11.667 6.417L11.667 9.333L8.75 7M0.583 4.083L8.75 4.083L8.75 10.5L5.25 10.5L2.333 12.833L2.333 10.5L0.583 10.5L0.583 4.083Z"
        />
        </svg>
        );
};


