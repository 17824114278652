import { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Button, DatePicker, Select } from "@pankod/refine-antd";
import dayjs from "dayjs";
import { Lead } from "interfaces";
import allUsedCarsService from "services/allusedCarsService";
import datetimeHelper from "utils/dateTimeHelper";
import masterData from "utils/masterData";
import AddCommentPopup from "./AddComment";
interface LeadProps {
  lead: Lead | null;
  callback: any;
  show: boolean;
}

const LeadStatusChangePopup = (props: LeadProps) => {
  var { lead, callback, show } = props;

  const [showin, setShow] = useState<boolean>(false);

  const [leadObj, setLead] = useState<Lead>();

  const [showCommentPopup, setShowCommentPopup] = useState<boolean>(false);

  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setLead(lead!);
    setShow(show);
    setComment("");
  }, []);

  useEffect(() => {
    setLead(lead!);
    setShow(show);
    setComment("");
  }, [show]);
  function handleCommentChange(com: string) {
    const updatedLeadObj = { ...leadObj, comments: com };
    setLead(updatedLeadObj);
  }

  function handleCancel(event: any) {
    callback();
    setShow(false);
  }
  async function handleCallback() {
    if (callback) {
      await allUsedCarsService.changeLeadStatusByLead(leadObj!);
      callback(leadObj);
      setShow(false);
    }
  }

  return (
    <div
      className="filterspopupWrp"
      style={{ display: showin ? undefined : "none" }}
    >
      <div className="filtersScrollArea">
        <div className="seperate-filter-wrp">
          <h4>Set Lead Status</h4>
          <Select
            style={{ width: "100%" }}
            value={leadObj?.leadStatus}
            onChange={(val) => {
              const updatedLeadObj = { ...leadObj, leadStatus: val };
              setLead(updatedLeadObj);
            }}
            defaultValue="ALL"
            options={masterData.getLeadStatusData()}
          />
        </div>
        <div className="seperate-filter-wrp">
          <h4>Set Call Status</h4>
          <Select
            style={{ width: "100%" }}
            value={leadObj?.leadCallStatus}
            onChange={(val) => {
              const updatedLeadObj = { ...leadObj, leadCallStatus: val };
              setLead(updatedLeadObj);
            }}
            defaultValue="ALL"
            options={masterData.getCallStatusData()}
          />
        </div>
        <div className="seperate-filter-wrp">
          <h4>Drop reason</h4>
          <Select
            style={{ width: "100%" }}
            value={leadObj?.leadDropReason}
            onChange={(val) => {
              const updatedLeadObj = { ...leadObj, leadDropReason: val };
              setLead(updatedLeadObj);
            }}
            defaultValue="ALL"
            options={masterData.getDropReasons()}
          />
        </div>
        <div className="seperate-filter-wrp">
          <DatePicker
            placeholder="first call time"
            value={
              leadObj?.firstCallTime
                ? dayjs(leadObj?.firstCallTime * 1000)
                : null
            }
            onSelect={(val) => {
              const updatedLeadObj = {
                ...leadObj,
                firstCallTime: val.valueOf() / 1000,
              };

              setLead(updatedLeadObj);
            }}
          />
          <DatePicker
            placeholder="second call time"
            value={
              leadObj?.secondCallTime
                ? dayjs(leadObj?.secondCallTime * 1000)
                : null
            }
            onSelect={(val) => {
              const updatedLeadObj = {
                ...leadObj,
                secondCallTime: val.valueOf() / 1000,
              };

              setLead(updatedLeadObj);
            }}
          />
          <DatePicker
            placeholder="third call time"
            value={
              leadObj?.thirdCallTime
                ? dayjs(leadObj?.thirdCallTime * 1000)
                : null
            }
            onSelect={(val) => {
              const updatedLeadObj = {
                ...leadObj,
                thirdCallTime: val.valueOf() / 1000,
              };

              setLead(updatedLeadObj);
            }}
          />
        </div>
        <div className="seperate-filter-wrp">
          <h4>Name</h4>
          <div className="res-filter-wrp">
            <textarea
              value={leadObj?.name ? leadObj?.name : ""}
              style={{ width: "100%", color: "gray" }}
              id="input"
              onChange={(e) => {
                const updatedLeadObj = { ...leadObj, name: e.target.value };
                setLead(updatedLeadObj);
              }}
            />
          </div>
        </div>
        <div className="seperate-filter-wrp">
          <h4>Phone Number</h4>
          <div className="res-filter-wrp">
            <textarea
              value={leadObj?.phoneNumber ? leadObj?.phoneNumber : ""}
              style={{ width: "100%", color: "gray" }}
              id="input"
              onChange={(e) => {
                const updatedLeadObj = {
                  ...leadObj,
                  phoneNumber: e.target.value,
                };
                setLead(updatedLeadObj);
              }}
            />
          </div>
        </div>
        <div className="seperate-filter-wrp">
          <h4>Location</h4>
          <div className="res-filter-wrp">
            <textarea
              value={leadObj?.location ? leadObj?.location : ""}
              style={{ width: "100%", color: "gray" }}
              id="input"
              onChange={(e) => {
                const updatedLeadObj = {
                  ...leadObj,
                  location: e.target.value,
                };
                setLead(updatedLeadObj);
              }}
            />
          </div>
        </div>
        <div className="seperate-filter-wrp">
          <h4>Comments</h4>
          <div className="res-filter-wrp">
            <textarea
              value={leadObj?.comments ? leadObj?.comments : ""}
              style={{ width: "100%", color: "gray" }}
              id="input"
              disabled={true}
            />
          </div>
          <Button
            className="filtersApplybtn"
            onClick={() => {
              setShowCommentPopup((prev) => {
                return !prev;
              });
            }}
          >
            Add Comment
          </Button>{" "}
          <AddCommentPopup
            getComment={(comment) => {
              if (!leadObj?.comments) {
                handleCommentChange(
                  `${datetimeHelper.getCurrentDateTime()} : ${comment}`
                );
              } else {
                handleCommentChange(
                  comment.trim().length > 0
                    ? `${
                        leadObj?.comments
                      }\n${datetimeHelper.getCurrentDateTime()} : ${comment}`
                    : `${leadObj?.comments}`
                );
              }
            }}
            comment={comment}
            show={showCommentPopup}
            hidePopup={() => {
              setShowCommentPopup(false);
            }}
          />
        </div>
        <div className="seperate-filter-wrp">
          <h4>Additional Info</h4>
          <div className="res-filter-wrp">
            <span>{leadObj?.additionalInfo}</span>
          </div>
        </div>
      </div>
      <Button
        className="filtersApplybtn"
        onClick={() => {
          handleCallback();
        }}
      >
        Submit
      </Button>{" "}
      <Button className="filtersApplybtn" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  );
};

export default LeadStatusChangePopup;
