import { Avatar, Select, Show, Typography } from "@pankod/refine-antd";
import ClipboardModal from "components/clipboard_modal";
import { useEffect, useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import companyService from "services/companyService";

export const PaymentList = () => {
  const [companies, setCompanies] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [orderType, setOrderType] = useState<string>("");

  const carId = useRef<any>(null);
  const additionalInfo = useRef<any>(null);
  const amount = useRef<any>(null);

  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);

  const orderTypes = [
    "SUBSCRIPTION",
    "SERVICE",
    "VERIFY",
    "PHONE_ACCESS",
    "BOOKING",
    "PRODUCT",
    "AUCTION ",
  ];
  const { Text } = Typography;

  const getCompanies = async (searchStr: string) => {
    try {
      const res: any = await companyService.companies(searchStr);
      setCompanies(res[0].companies);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // console.log(error.response)
    }
  };

  const onSuggestionsFetchRequested = ({ value }: { value: any }) => {
    getCompanies(value);
  };
  const onSuggestionsClearRequested = () => {
    // setCompanies([])
  };
  const getSuggestionValue = (suggestion: any) => suggestion.companyName;
  const renderSuggestion = (suggestion: any) => (
    <div className="autocomplete-menu">
      <Avatar size={32} src={suggestion.imageUrl} />
      <Text style={{ marginLeft: "16px" }}>{suggestion.companyName}</Text>
      <Text style={{ marginLeft: "16px" }}>{suggestion.addressInfo?.city}</Text>
    </div>
  );
  const handleChange = (event: any, { newValue }: { newValue: any }) => {
    setValue(newValue);
  };

  useEffect(() => {
    setOrderType(orderTypes[0]);
  }, []);

  const getPaymentUrl = async () => {
    if (value.trim() === "") {
      alert("Company cannot be empty!");
      return;
    }
    if (amount.current.value === "") {
      alert("Amount cannot be empty!");
      return;
    }
    if (amount.current.value < 1) {
      alert("Amount cannot be less than 1!");
      return;
    }

    const data = {
      fromCompanyId: companies.filter(
        (com: { companyName: string }) => com.companyName === value
      )[0]["companyId"],
      carId: carId.current.value,
      orderType: orderType,
      additionalInfo: additionalInfo.current.value,
      amountInLowestDen: parseInt(amount.current.value) * 100,
    };

    const [res, status] = await companyService.createPaymentRequest(data);
    console.log(res, status);
    if (status == 200) {
      setPaymentUrl(res);
    }
  };

  return (
    <>
      <ClipboardModal
        value={paymentUrl ?? ""}
        show={paymentUrl != null}
        onClose={() => {
          setPaymentUrl(null);
        }}
        // onCopy={() => {
        //   navigator.clipboard.writeText(paymentUrl ?? "");
        // }}
      />

      <Show
        title="Generate Payment Link"
        isLoading={loading}
        headerButtons={() => <></>}
      >
        <div className="form form__fields">
          <text>Select Company * :</text>
          <div className="form__autocomplete">
            <Autosuggest
              suggestions={companies}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={{
                placeholder: "Search Company",
                value,
                onChange: handleChange,
              }}
            />
          </div>
          <text>Select Order Type * :</text>
          <div className="form__dropdown">
            <Select
              style={{ width: "100%" }}
              value={orderType}
              onChange={(val) => {
                setOrderType(val);
              }}
              options={orderTypes.map((type) => ({
                label: type,
                value: type,
              }))}
            />
          </div>
          <text>Enter Car ID :</text>
          <div className="form__field">
            <input
              className="form__input"
              ref={carId}
              type="text"
              placeholder="Enter Car ID"
            />
          </div>
          <text>Enter Additional Information :</text>
          <div className="form__field">
            <textarea
              placeholder="Enter Additional Information"
              ref={additionalInfo}
            />
          </div>
          <text>Amount * :</text>
          <div className="form__field">
            <input
              className="form__input"
              type="number"
              placeholder="Enter Amount"
              ref={amount}
              min={1}
            />
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "40px" }}>
          <button
            className="form__btn"
            style={{ marginRight: "10px" }}
            onClick={getPaymentUrl}
          >
            Generate Payment Link
          </button>
          <button className="form__btn">Reset</button>
        </div>
      </Show>
    </>
  );
};
