import {
  IResourceComponentsProps,
  useMany,
  useNavigation,
} from "@pankod/refine-core";

import {
  AntdList,
  Avatar,
  Button,
  Card,
  CreateButton,
  EditButton,
  Icons,
  List,
  NumberField,
  PageHeader,
  Pagination,
  Select,
  ShowButton,
  Space,
  Table,
  TextField,
  Typography,
  useTable,
} from "@pankod/refine-antd";
//@ts-ignore
import dateFormat from "dateformat";
import { IMessage, IPost, IUserCompany } from "interfaces";
import { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
import companyService from "services/companyService";

import RouterProvider from "@pankod/refine-react-router-v6";
import { defaultConfig } from "config";
import authService from "services/authService";
import { getUnreadMsg, updateUnreadMsg } from "utils/messageManager";

import AcceptReject from "components/AcceptReject";
import Calling from "components/Calling";
import FiltersPopup from "components/FiltersPopup";
import { notifySuccess } from "components/Notification";
import Peer, { PeerConnectOption } from "peerjs";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { InboxShow } from "./show";
export const InboxList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const {
    PhoneOutlined,
    LeftOutlined,
    RightOutlined,
    FilterOutlined,
    CloseOutlined,
  } = Icons;
  const [unreadMsgMap, setUnReadMap] = useState<Map<string, number>>();
  const [usercompanies, setUsrCompany] = useState<IUserCompany[]>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [callbackRequest, setCallbackRequest] = useState(false);
  const [filters, setFilters] = useState({
    chatStatus: "ALL",
    callbackRequest: false,
    response: [],
  });
  const [chatType, setChatType] = useState("ALL");
  const [pageNumber, setPageNumber] = useState(0);
  const [showFilterspopup, setShowFiltersPopup] = useState(false);
  const pageSize = 50;
  const userCompanyId = RouterProvider.useParams().id;
  const audioRef = useRef(null);
  let myArray;
  let userId: string | null = null;
  let companyId: string | null = null;
  if (userCompanyId) {
    myArray = userCompanyId.split("--");
    userId = myArray[0];
    companyId = myArray[1];
  }
  function sendWebNotification(msg: string) {
    try {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else {
        console.log("This browser supports desktop notification");
        Notification.requestPermission();
        new Notification(msg);
      }
    } catch {}
  }

  useEffect(() => {
    async function getuserscompanyInfo() {
      const [userscompany, status] = await companyService.getLinkedUsers(
        pageNumber,
        pageSize
      );
      if (status == 200) {
        const message = JSON.stringify({
          companyId: authService.getLoggedInUserCompanyId(),
          action: "setWidget",
        });

        if (window.parent) window.parent.postMessage(message, "*");

        setUnReadMap(getUnreadMsg());
        localStorage.setItem(
          "listOfUserCompanies",
          JSON.stringify(userscompany)
        );
        setUsrCompany(userscompany as IUserCompany[]);
      }
      setLoading(false);
    }
    getuserscompanyInfo();
  }, [pageNumber]);

  const handleFilters = (filterName: string, filterValue: any) => {
    setFilters((prev) => {
      return {
        ...prev,
        [filterName]: filterValue,
      };
    });
  };

  const handleResponseFilters = (res: string) => {
    let mltres: string[] = [...filters.response];
    if (mltres.includes(res)) {
      mltres = mltres.filter((sn) => sn != res);
    } else mltres.push(res);
    handleFilters("response", mltres);
  };

  const filtersPresent = () => {
    if (
      filters.callbackRequest ||
      filters.response.length > 0 ||
      filters.chatStatus != "ALL"
    )
      return true;
    return false;
  };

  const resetFilters = () => {
    const tempFilters = {
      chatStatus: "ALL",
      callbackRequest: false,
      response: [],
    };
    setFilters(tempFilters);
    getStatusChats(tempFilters);
  };

  let ws: WebSocket;
  const connect = () => {
    // console.log("this is called")
    if (ws === undefined || (ws && ws.readyState === 3)) {
      ws = new WebSocket(
        defaultConfig.urls["ws"] + "?userId=" + authService.getLoggedInUserId()
      );
      ws.onopen = function () {
        // console.log("opened")
      };
      ws.onmessage = function (payload) {
        if (payload.data) {
          if (
            JSON.parse(payload.data).metadata?.callStatus === "ENDED" ||
            JSON.parse(payload.data).metadata?.callStatus === "DECLINED"
          ) {
            (window as any).callingWidget("endcall");
          }
          if (
            JSON.parse(payload.data).metadata?.callStatus === "ACCEPTED" &&
            JSON.parse(payload.data).metadata?.agentId ===
              localStorage.getItem("userId")
          ) {
            // (window as any).callingWidget("endcall");
            // console.log("mheah" + payload.data);
            try {
              (window as any).callingWidget("event", "call", {
                calleeId: JSON.parse(payload.data).metadata?.callUserId,
                type: "audio",
                onEndCall,
                onInitiateCall,
              });

              (window as any).callingWidget("endcall");
              (window as any).callingWidget("event", "call", {
                calleeId: JSON.parse(payload.data).metadata?.callUserId,
                type: "audio",
                onEndCall,
                onInitiateCall,
              });
            } catch (error) {
              console.log(error);
            }
          }
          if (JSON.parse(payload.data).fromto != "OUTGOING") {
            //@ts-ignore
            audioRef.current.play();
          }
          handleOnMsgReceived(JSON.parse(payload.data));
        }
      };

      ws.onclose = function () {
        //   console.log("closed")
      };
    }
  };
  useEffect(() => {
    let interval = setInterval(connect, 5000);
    return () => {
      if (ws) ws.close();
      clearInterval(interval);
    };
  }, []);

  const redirect = (id: string) => {
    navigate("/inbox/show/" + id);
  };
  const handleOnMsgReceived = (msg: IMessage) => {
    if (msg && msg.fromto == "INCOMING") {
      sendWebNotification(msg.content);
    }

    updateUnreadMsg(msg);
    setUnReadMap(getUnreadMsg());
    if (localStorage.getItem("listOfUserCompanies")) {
      let chats: IUserCompany[] = JSON.parse(
        localStorage.getItem("listOfUserCompanies")!
      ) as IUserCompany[];
      let isfound = false;
      const chatp = chats?.filter(
        (a) => a.userId == msg.userId && a.company.companyId == msg.companyId
      );
      let indexp = chats?.findIndex(
        (a) => a.userId == msg.userId && a.company.companyId == msg.companyId
      );
      chatp.forEach((b) => {
        isfound = true;
        if (b.lastConversationInfo) {
          b.lastConversationInfo.msg = msg.content;
          b.createdAt = msg.createdAt;
          b.updatedAt = msg.createdAt;
        }
      });
      if (isfound) {
        const chat: IUserCompany = {
          company: { companyId: msg.companyId!, companyName: msg.companyName! },
          userId: msg.userId!,
          updatedAt: msg.createdAt,
          createdAt: msg.createdAt,
          lastConversationInfo: {
            msg: msg.content,
            from: msg.fromto,
          },
          isActive: true,
          callRequestStatus:
            msg.content === "Callback requested"
              ? "CALL_REQUESTED"
              : chatp[0].callRequestStatus,
          callUserId: chatp[0].callUserId || msg.metadata?.callUserId,
          webRequest: chatp[0].webRequest || msg.metadata?.isWebRequest,
          status: chatp[0].status,
        };
        chats?.splice(indexp, 1);
        chats?.splice(0, 0, chat);
      } else {
        const chat: IUserCompany = {
          company: { companyId: msg.companyId!, companyName: msg.companyName! },
          userId: msg.userId!,
          updatedAt: msg.createdAt,
          createdAt: msg.createdAt,
          lastConversationInfo: {
            msg: msg.content,
            from: msg.fromto,
          },
          isActive: true,
          callRequestStatus:
            msg.content === "Callback requested" ? "CALL_REQUESTED" : "",
          callUserId: msg.metadata?.callUserId,
          webRequest: msg.metadata?.isWebRequest,
          status: "",
        };
        chats?.splice(0, 0, chat);
      }
      setUsrCompany(JSON.parse(JSON.stringify(chats)));
      localStorage.setItem("listOfUserCompanies", JSON.stringify(chats));
    }
  };
  const getStatusChats = async (tempFilters?: any) => {
    const connectedCompany = localStorage.getItem("companyId") || "";
    const [chatfilters, status] = await companyService.getStatusChats(
      connectedCompany,
      tempFilters?.chatStatus
        ? tempFilters?.callbackRequest
        : filters.callbackRequest,
      tempFilters?.chatStatus || filters.chatStatus,
      tempFilters?.response || filters.response
    );
    if (status == 200) {
      setUsrCompany(chatfilters);
      setShowFiltersPopup(false);
    }
  };

  const onEndCall = async () => {
    //@ts-ignore
    const callingCompany = JSON.parse(localStorage.getItem("current-company"));
    const [sendEndCallStatus, status] = await companyService.callingStatus(
      callingCompany?.userId,
      "ENDED",
      callingCompany?.userId || "",
      callingCompany?.company.companyId || ""
    );

    if (status === 200) {
    }
  };

  const onInitiateCall = async () => {
    //@ts-ignore
    const callingCompany = JSON.parse(localStorage.getItem("current-company"));
    const [sendEndCallStatus, status] = await companyService.callingStatus(
      callingCompany?.callUserId || "",
      "STARTED",
      callingCompany?.userId || "",
      callingCompany?.company.companyId || ""
    );
    if (status === 200) {
      console.log(sendEndCallStatus);
    }
  };

  const makeCall = async (item: IUserCompany) => {
    localStorage.setItem("current-company", JSON.stringify(item));
    const [sendEndCallStatus, status] = await companyService.callingStatus(
      item?.callUserId || "",
      "INITIATED",
      item?.userId || "",
      item?.company.companyId || ""
    );
    if (status === 200) {
      console.log(sendEndCallStatus);
    }
    if (item.webRequest && item.webRequest) {
      (window as any).callingWidget("event", "call", {
        //     calleeId:item.callUserId,
        //     type: "audio",
        //     onEndCall,
        //     onInitiateCall,
        //   });
        // }else{
        //   (window as any).callingWidget("event", "isPrecalling", {
        calleeId: item.callUserId,
        type: "audio",
        onEndCall,
        onInitiateCall,
      });
    }
  };

  // effect runs when user state is updated
  useEffect(() => {}, [usercompanies, unreadMsgMap]);
  const renderItem = (item: IUserCompany) => {
    // onClick={() => redirect(item.userId+ 'X'+item.company.companyId)}
    return (
      <AntdList.Item
        className={
          companyId &&
          companyId === item.company.companyId &&
          userId &&
          userId === item.userId
            ? `card3 card3highlight`
            : "card3"
        }
      >
        <div className="inbox-card">
          <div>
            <Avatar size={45} src={item.company.logo} />
          </div>
          <div className="contentWrapper">
            <div
              className="titledate-div"
              onClick={() => {
                localStorage.setItem("selected-company", JSON.stringify(item));
                redirect(item.userId + "--" + item.company.companyId);
              }}
            >
              <div className="company-info-wrapper">
                <p className="company-user">
                  {item.userId} {item.status && <span>- {item.status}</span>}
                </p>
                <p className="company-title">{item.company?.companyName}</p>
              </div>
              <div style={{ textAlign: "right" }}>
                {item.createdAt && (
                  <Text className="user-date">
                    {dateFormat(
                      new Date(item.createdAt! * 1000),
                      "dd-mmm hh:MM TT"
                    )}
                  </Text>
                )}
              </div>
            </div>
            <div
              className="message-div"
              onClick={() => {
                localStorage.setItem("selected-company", JSON.stringify(item));
                redirect(item.userId + "--" + item.company.companyId);
              }}
            >
              <p className="user-msg">{item.lastConversationInfo?.msg}</p>
              <div style={{ textAlign: "right" }}>
                {/* {unreadMsgMap?.get(item.userId) && <div className="unreadMsgMadiv-msg">{"1"}</div>} */}
                {item.callUserId && (
                  <Button
                    className="callback-btn"
                    onClick={() => {
                      makeCall(item);
                    }}
                  >
                    <PhoneOutlined />
                    Callback
                  </Button>
                )}
              </div>
            </div>
            {/* <div style={{textAlign:'right'}} >
                            {item.callUserId && <Button className="callback-btn" onClick={() => {
                                localStorage.setItem("current-company",JSON.stringify(item));
                                (window as any).callingWidget('event','call',{calleeId:"benam-827364872364823872443523453530",type:'audio',onEndCall,onInitiateCall})
                            }} ><PhoneOutlined />Callback</Button>}
                        </div> */}
          </div>
        </div>
        {/* <hr/> */}
      </AntdList.Item>
    );
  };
  return (
    <PageHeader className="chatheaderwrapper" ghost={false}>
      <ToastContainer />
      <audio src="/benamaudio.mp3" ref={audioRef} />
      <div className="inbox-wrapper">
        <div
          className={
            userCompanyId ? `companylist m-companylist` : "companylist"
          }
        >
          <div className="inbox-header">
            <h3
              style={{ margin: 0, marginLeft: "10px", cursor: "pointer" }}
              onClick={() => setShowFiltersPopup(!showFilterspopup)}
            >
              <FilterOutlined /> Filters
            </h3>
            {filtersPresent() && (
              <p
                style={{
                  margin: 0,
                  textAlign: "right",
                  fontSize: "10px",
                  cursor: "pointer",
                }}
                onClick={resetFilters}
              >
                <CloseOutlined /> Clear filters
              </p>
            )}
            <FiltersPopup
              showFilterspopup={showFilterspopup}
              getStatusChats={getStatusChats}
              filters={filters}
              handleFilters={handleFilters}
              handleResponseFilters={handleResponseFilters}
            />
            <div className="tabs">
              <div
                className={`tab ${
                  filters.chatStatus === "ALL" &&
                  filters.callbackRequest === false
                    ? "active"
                    : ""
                } `}
                onClick={() => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      callbackRequest: false,
                      chatStatus: "ALL",
                    };
                  });
                  getStatusChats({ chatStatus: "ALL", callbackRequest: false });
                }}
              >
                All
              </div>
              <div
                className={`tab ${
                  filters.chatStatus === "ASSIGNED" &&
                  filters.callbackRequest === false
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      callbackRequest: false,
                      chatStatus: "ASSIGNED",
                    };
                  });
                  getStatusChats({
                    chatStatus: "ASSIGNED",
                    callbackRequest: false,
                  });
                }}
              >
                Assigned
              </div>
              <div
                className={`tab ${
                  filters.callbackRequest === true ? "active" : ""
                }`}
                onClick={() => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      callbackRequest: true,
                      chatStatus: "ALL",
                    };
                  });
                  getStatusChats({ chatStatus: "ALL", callbackRequest: true });
                }}
              >
                Callback Req.
              </div>
            </div>
          </div>
          <AntdList
            className="listWrapper"
            loading={isLoading}
            dataSource={usercompanies}
            renderItem={renderItem}
          />
          <div className="pagination-list">
            {pageNumber > 0 ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setPageNumber((prev) => prev - 1)}
              >
                <LeftOutlined />
              </div>
            ) : (
              <div></div>
            )}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setPageNumber((prev) => prev + 1)}
            >
              <RightOutlined />
            </div>
          </div>
        </div>

        <div className={userCompanyId ? `chatlist m-chatlist` : "chatlist"}>
          {userCompanyId && <InboxShow />}
        </div>
      </div>
    </PageHeader>
  );
};
const styles = {
  bubble: {
    fontFamily: '"DM Sans", sans-serif',
    background: "#fad901b3",
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    // "boxShadow": "rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px",
    border: "none",
    transition: "box-shadow 150ms ease-in-out 0s",
    zIndex: "5000",
    width: "32px",
    height: "32px",
    bottom: "32px",
    right: "32px",
    color: "#FFF",
    borderRadius: "50px",
  },
};
function getLoggedInUserCompanyId() {
  throw new Error("Function not implemented.");
}
