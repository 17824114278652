import {
  IResourceComponentsProps,

} from "@pankod/refine-core";

import {
  Button,
  AntdList,
  PageHeader,
  Typography,
  Avatar,
  Icons,
} from "@pankod/refine-antd";
//@ts-ignore


import { useEffect, useState, useRef } from "react";
import allUsedCarsService from "services/allusedCarsService";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import {  Product } from "interfaces";
import CreateNewLeadPopup from "components/CreateNewLeadPopup";
import productService from "services/productService";
import { notifySuccess } from "components/Notification";
import ProductEditPopup from "components/ProductEditPopup";
export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const { Text } = Typography;
  const {
    LeftOutlined,
    RightOutlined,

  } = Icons;
  const [products, setProducts] = useState<Product[]>();
  const [companyId, setCompanyId] = useState<string|undefined>("");
  const [selectedProductId, setSelectedProductId] = useState<string|undefined>("");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string|null>("DRAFT");
  const [pageNumber, setPageNumber] = useState(0);
  const pageSize = 100;
  const [show, setShow] =  useState<boolean>(false);
  const [showCreateNewPopup, setshowCreateNewPopup] =  useState<boolean>(false);

  const [selectedProduct, setSelectedProduct] =  useState<Product|null>(null);
 
  useEffect(() => {
    getProducts(selectedFilter);
  }, [pageNumber]);

  async function getProducts(productStatus:string|null) {
    const [productsRes, status] = await productService.getProducts(productStatus,
      pageNumber,
      pageSize, companyId
    );
    if (status == 200 || status ==204) {  
      setProducts(productsRes as Product[]);
    }
    setLoading(false);
  }

  const  handleOnClick =(product:Product) => {
    setSelectedProduct(product);
    setShow(true);
  };


  function onProductIdChange(event: any) {

    setSelectedProductId(event.target.value);
}

const getByProductId = async () =>
{
  console.log(selectedProductId)
  const [productRes, status] = await productService.getProduct(selectedProductId
  );
  if (status == 200 || status ==204) {  
    setProducts([productRes as Product]);
  }

}
const clearProductId =() =>
{
  setSelectedProductId("");
  getProducts(selectedFilter);
}

  const handleStatusChange = async (product:Product, isApproved:boolean) =>
  {
    const [productsRes, status] = await productService.changeProductStatus(product.productId!,isApproved,product.companyId!);

    if(status == 200)
    {
      notifySuccess("Product updated successfully" );
  
      getProducts(selectedFilter);
    }
  }
  const postVideo = async (product:Product, isMain:boolean) =>
    {
      const [productsRes, status] = await allUsedCarsService.postVideo(product.externalReferenceId!,isMain);
  
      if(status == 200)
      {
        console.log(JSON.stringify(productsRes))
        notifySuccess("video request generated successfully" );
      }
    }

  useEffect(() => {
   
  }, [show]);

  useEffect(() => {
   
  }, [JSON.stringify(products)]);

  const renderItem = (item: Product) => {
  
    return (
      <AntdList.Item
        className={
  "card3"
        }
      >
        <div
        
        >
       
          <div className="contentWrapper">
       
            <div
              className="product-div"
            >
                {item.productId &&  <p className="user-msg"><b>ProductId: </b> {item.productId}</p>}
                <p className="user-msg">
                <b>Status: </b> {item.productStatus}
                </p>
                {item.createdAt && (
                  <p className="user-msg">
                    <b>Created At: </b>{dateFormat(
                      new Date(item.createdAt! * 1000),
                      "dd-mmm hh:MM TT"
                    )}
                  </p>
                )}
             {item.title &&  <p className="user-msg">Title: {item.title}</p>}
             {item.isBlacklisted &&  <p className="user-msg"><b>Blacklisted: </b> {item.isBlacklisted}</p>}
            {item.externalReferencePath &&  <p className="user-msg">Url: <a target="_blank" href={`https://www.allusedcars.in/cars/${item.externalReferencePath}`} >{`https://www.allusedcars.in/cars/${item.externalReferencePath}`}</a></p>}
            {item.finalPrice &&  <p className="user-msg"><b>Final Price: </b> {item.finalPrice}</p>}
            {item.mrp &&  <p className="user-msg"><b>MRP Price: </b> {item.mrp}</p>}
            {item.companyId &&  <p className="user-msg"><b>CompanyId: </b>{item.companyId}</p>} 
            {item.cityCode &&  <p className="user-msg"><b>City: </b> {item.cityCode}</p>} 
            {item.description &&  <p className="user-msg"><b>Description: </b> {item.description}</p>} 
            <br/>
            <div>
            {item.productImages && item.productImages.map((img, indx) => (
             <img style={{width:"100px"}} src={`${img}`}></img>
            
             ))}
             </div>
            <br/>
            {item.attributes &&  <p><b>Attributes: </b> {JSON.stringify(item.attributes)}</p>} 
            <br/>
            {item.carAttributes &&  <p ><b>carAttributes Info: </b> {JSON.stringify(item.carAttributes)}</p>} 
           
       </div>
            <Button className="filtersApplybtn" onClick={()=>{ handleOnClick(item);}} >Edit</Button>
            <Button className="filtersApplybtn" onClick={()=>{ handleStatusChange(item, true);}} >Approve</Button>
            <Button className="filtersApplybtn" onClick={()=>{ handleStatusChange(item, false);}} >Reject</Button>
            <Button className="filtersApplybtn" onClick={()=>{ postVideo(item, false);}} >Post Video Local</Button>
            <Button className="filtersApplybtn" onClick={()=>{ postVideo(item, true);}} >Post Video Main</Button>
          </div>
        </div>
      </AntdList.Item>
    );
  };
  return (
    <PageHeader className="chatheaderwrapper" ghost={false}>
      <ToastContainer />
     
      <div className="inbox-wrapper">
        <div   
            className="ProductList"
          >
          
          <div className="inbox-header">
        {/* <div  className="newLeadButtonContainer" > 
        <Button className="filtersApplybtn"  style={{ width: '100px' }}  onClick={()=>{ createNewLeadPopup();}} >Add Lead</Button>
        </div> */}
          
          <CreateNewLeadPopup 

              callback={() => { setshowCreateNewPopup(false); getProducts(selectedFilter); } }
              show={showCreateNewPopup}  />
          <ProductEditPopup 
 product={selectedProduct}
 callback={()=>{ setShow(false);setSelectedProduct(null); getProducts(selectedFilter); }}
 show={show}
 />

<div>
<div style={{paddingLeft:"10px"}}>
               
               ProductId: <input value={selectedProductId} id="input" onChange={onProductIdChange} />  
                <Button className="filtersApplybtn" onClick={getByProductId} >Submit</Button>
                <Button className="filtersApplybtn" onClick={clearProductId} >Clear</Button>
            </div>
  </div>
          <div className="tabs">
              
              <div
               className={`tab ${
                selectedFilter === "DRAFT"? 
                   "active"
                  : ""
              }`}
              onClick={() => {
                setSelectedFilter("DRAFT");
                getProducts("DRAFT");
              }}
                  >
                   DRAFT
                  </div>
           
                  <div
                    className={`tab ${
                      selectedFilter === "APPROVED"? 
                         "active"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedFilter("APPROVED");
                      getProducts("APPROVED");
                    }}
                  >
                    APPROVED
                  </div>
                  {/* <div
                    className={`tab ${
                      selectedFilter==null? "active"
                        : ""
                    } `}
                    onClick={() => {
                      setSelectedFilter(null);
                      getProducts(null);
                    }}
                  >
                    ALL
                  </div> */}
     
                </div>
          </div>
          <AntdList
            className="listWrapper"
            loading={isLoading}
            dataSource={products}
            renderItem={renderItem}
          />
          <div className="pagination-list">
            {pageNumber > 0 ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setPageNumber((prev) => prev - 1)}
              >
                <LeftOutlined />
              </div>
            ) : (
              <div></div>
            )}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setPageNumber((prev) => prev + 1)}
            >
              <RightOutlined />
            </div>
          </div>
        </div>
       
      </div>
    </PageHeader>
  );
};


