import React, { useEffect, useState } from "react";
import { GetOneResponse, IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import {
    Edit,
    Form,
    Input,
    Select,

} from "@pankod/refine-antd";

import { useNavigate } from "react-router-dom";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICompany } from "interfaces";
import companyService from "services/companyService";
import { useNotification } from "@pankod/refine-core";

export const CompanyInfoCRMEdit: React.FC<IResourceComponentsProps> = () => {
    const [form] = Form.useForm();
    const { open } = useNotification();

    const navigate = useNavigate();
    const [company, setCompany] = useState<ICompany>();
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        async function getcompanyInfo() {
            const [company, status] = await companyService.getCompanyInfo();
            if (status == 200)
                setCompany(company as ICompany);
 
            setLoading(false)
        }
        getcompanyInfo();
    }, [])

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        form.resetFields();
    }, [company]);

    const save = async () => {
        let merged = { ...company, ...form.getFieldsValue() };  
        const [companyUpdated, status] = await companyService.updateCompanyInfo(merged);
        if (status == 200) {
            open?.({ message: "successfully saved", type: "success" })
            navigate(-1);
        }
        else {
            open?.({ message: "Error Saving Information", type: "error" })
        }
    }

    return (
        <Edit
            title="CRM Information"
            isLoading={isLoading}
            recordItemId={company?.companyId}
            saveButtonProps={{ onClick: save }}
        >

            <Form form={form} initialValues={{ ...company }} layout="vertical">
                <Form.Item
                    label="Deeplink Url"
                    name="deferredDeepLink"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>

                </Form.Item>
                <Form.Item
                    label="CRM Provider"
                    name={['crmInfo', 'crmProvider']}

                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "CHATWOOT",
                                value: "CHATWOOT",
                            },
                            {
                                label: "ZOHOCRM",
                                value: "ZOHOCRM",
                            }
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="CRM AccountId"
                    name={['crmInfo', 'accountId']}
              
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
      
                <Form.Item
                    label="CRM Inbox Indentifier"
                    name={['crmInfo', 'inboxIndentifier']}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="CRM ClientId"
                    name={['crmInfo', 'clientId']}
        
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="CRM Client Secret"
                    name={['crmInfo', 'clientSecret']}

                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="CRM auth token"
                    name={['crmInfo', 'authToken']}

                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="CRM refresh token"
                    name={['crmInfo', 'refreshToken']}

                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="CRM code"
                    name={['crmInfo', 'code']}

                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="CRM BaseUrl"
                    name={['crmInfo', 'baseUrl']}

                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

            </Form>
        </Edit>
    );
};
