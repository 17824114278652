import { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Button, Select } from "@pankod/refine-antd";
import { Lead } from "interfaces";
import Switch from "react-switch";
import allUsedCarsService from "services/allusedCarsService";

interface LeadProps {
  callback: any;
  show: boolean;
}

const CreateNewLeadPopup = (props: LeadProps) => {
  const { callback, show } = props;
  const [lead, setLead] = useState<Lead>();
  const [showin, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(show);
  }, []);

  useEffect(() => {
    setShow(show);
  }, [show]);

  function handleCancel(event: any) {
    callback();
    setShow(false);
  }

  async function handleCallback(event: any) {
    if (callback) {
      const [leadx, status] = await allUsedCarsService.saveLead(lead!);
      if (status === 200) {
        callback(lead);
      } else {
        callback();
      }
      setShow(false);
    }
  }
  return (
    <div
      className="filterspopupWrp"
      style={{ display: showin ? "block" : "none" }}
    >
      <div className="res-filter-wrp">
        {/* <label>Name</label> */}
        <input
          type="text"
          name="name"
          value={lead?.name}
          required
          placeholder="Your Name *"
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, name: e.target.value })}
        />
      </div>
      <div className="res-filter-wrp">
        {/* <label>Phone Number</label> */}
        <input
          type="text"
          name="phoneNumber"
          value={lead?.phoneNumber}
          required
          placeholder="Your Phone Number *"
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, phoneNumber: e.target.value })}
        />
      </div>
      <div className="res-filter-wrp">
        {/* <label>City</label> */}
        <input
          type="text"
          name="location"
          placeholder="Your city"
          value={lead?.location}
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, location: e.target.value })}
        />
      </div>
      <div className="res-filter-wrp">
        {/* <label>Who are you?</label> */}
        <select
          value={lead?.carType}
          name="carType"
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, carType: e.target.value })}
        >
          <option value="">Which car are you looking for?</option>
          <option value="SUV/MUV">SUV/MUV</option>
          <option value="Sedan">Sedan</option>
          <option value="Hatchback">Hatchback</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className="res-filter-wrp">
        {/* <p>Tell us your query.</p> */}
        <textarea
          value={lead?.additionalInfo}
          rows={5}
          name="additionalInfo"
          placeholder="Your Query"
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, additionalInfo: e.target.value })}
        />
        {/* <input /> */}
      </div>
      <div className="seperate-filter-wrp">
        <div className="res-filter-wrp">
          <textarea
            value={lead?.comments}
            className="usertraxnform__field"
            placeholder="Comments"
            id="input"
            onChange={(e) => setLead({ ...lead, comments: e.target.value })}
          />
        </div>
      </div>
      <div className="res-filter-wrp">
        {/* <label>City</label> */}
        <input
          type="text"
          name="dealerId"
          placeholder="DealerId"
          value={lead?.dealerId}
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, dealerId: e.target.value })}
        />
      </div>
      <div className="res-filter-wrp">
        {/* <label>City</label> */}
        <input
          type="text"
          name="dealerName"
          placeholder="Dealer Name"
          value={lead?.dealerName}
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, dealerName: e.target.value })}
        />
      </div>
      <div className="res-filter-wrp">
        {/* <label>City</label> */}
        <input
          type="text"
          name="carId"
          placeholder="carId"
          value={lead?.carId}
          className="usertraxnform__field"
          onChange={(e) => setLead({ ...lead, carId: e.target.value })}
        />
      </div>
      <Button className="filtersApplybtn" onClick={handleCallback}>
        Submit
      </Button>{" "}
      <Button className="filtersApplybtn" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  );
};

export default CreateNewLeadPopup;
