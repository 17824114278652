import { useList, useOne, useUpdate } from "@pankod/refine-core";
import authService from "services/authService";
import companyService from "services/companyService";
import {
    Typography,
    Button,
    Show,
    Space,
    MarkdownField,
    TextField,
    RefreshButton,
    EditButton,
} from "@pankod/refine-antd";

import { ICompany } from "interfaces";
import { useEffect, useState } from "react";

const { Title, Text } = Typography;

export const CompanyInfoCRM: React.FC = () => {

    const [company, setCompany] = useState<ICompany>();
    const [isLoading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        async function getcompanyInfo() {
            const [company,status] = await companyService.getCompanyInfo();
            if(status ==200)
                setCompany(company as ICompany);
                setLoading(false)
        }
        getcompanyInfo();
     }, [])


    return (
        <Show
            title="CRM Information"
            isLoading={isLoading}
            canEdit={true}
            recordItemId={company?.companyId}
            canDelete={false}
            headerButtons={<EditButton recordItemId={company?.companyId}/>}
            pageHeaderProps={{
                backIcon: true,
            }}
            actionButtons={
                <Space
                    key="action-buttons"
                    style={{ float: "right", marginRight: 24 }}
                >
                   
                </Space>
            }
        >
            <Title level={5}>CompanyId</Title>
            <Text>{company?.companyId}</Text>
            <Title level={5}>Deeplink Url</Title>
            <Text>{company?.deferredDeepLink}</Text>

            <Title level={5}>CRM Provider</Title>
            <Text>{company?.crmInfo?.crmProvider}</Text>

            <Title level={5}>CRM AccountId</Title>
            <Text>{company?.crmInfo?.accountId}</Text>

            <Title level={5}>CRM Inbox Indentifier</Title>
            <Text>{company?.crmInfo?.inboxIndentifier}</Text>

            <Title level={5}>CRM ClientId</Title>
            <Text>{company?.crmInfo?.clientId}</Text>

            <Title level={5}>CRM Client Secret</Title>
            <Text>{company?.crmInfo?.clientSecret}</Text>

            <Title level={5}>CRM auth token</Title>
            <Text>{company?.crmInfo?.authToken}</Text>
            <Title level={5}>CRM refresh token</Title>
            <Text>{company?.crmInfo?.refreshToken}</Text>
            <Title level={5}>CRM code </Title>
            <Text>{company?.crmInfo?.code}</Text>
            <Title level={5}>CRM BaseUrl </Title>
            <Text>{company?.crmInfo?.baseUrl}</Text>
       
        </Show>
    );
};
