import {
  IResourceComponentsProps,
  userFriendlyResourceName,
} from "@pankod/refine-core";

import {
  AntdList,
  Avatar,
  Button,
  Collapse,
  Icons,
  PageHeader,
  Typography,
} from "@pankod/refine-antd";
//@ts-ignore

import dateFormat from "dateformat";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import allUsedCarsService from "services/allusedCarsService";

import CreateNewLeadPopup from "components/CreateNewLeadPopup";
import LeadAssignToPopup from "components/LeadAssignToPopup";
import LeadStatusChangePopup from "components/LeadStatusChangePopup";
import LeadsFiltersPopup from "components/LeadsFilterPopup";
import { Lead, LeadEnquiry } from "interfaces";
import { ToastContainer } from "react-toastify";
import authService from "services/authService";
import companyService from "services/companyService";
import SendWhatsappPopup from "components/SendWhatsappPopup";

const defaultFilters = {
  leadStatus: "NEW",
  callStatus: null,
  toDate: null,
  fromDate: null,
  phoneNumber: null,
  dealerId: null,
  received: null,
  assignedTo: null
};
const Panel = Collapse.Panel;
export const LeadList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const { LeftOutlined, RightOutlined } = Icons;
  const [leads, setLeads] = useState<Lead[]>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(0);
  const pageSize = 100;
  const [show, setShow] = useState<boolean>(false);
  const [showWhatsappSend, setShowWhatsappSend] = useState<boolean>(false);
  const [showAssignToPopup, setShowAssignToPopup] = useState<boolean>(false);
  const [showCreateNewPopup, setshowCreateNewPopup] = useState<boolean>(false);

  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [showFiltersPopup, setShowFiltersPopup] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [assignToButtonLoading, setAssignToButtonLoading] =
    useState<boolean>(false);
  const [leadId, setLeadId] = useState<string>("");
  const [slead,setLead] = useState<Lead|null>(null);

  const [userOptions, setUserOptions] = useState([]);
  const [filters, setFilters] = useState<{
    leadStatus: string | null;
    callStatus: string | null;
    toDate: number | null;
    fromDate: number | null;
    phoneNumber: string | null;
    dealerId: string | null;
    received: boolean|null;
    assignedTo: string | null;
  }>(defaultFilters);

  useEffect(() => {
    getLeads(
      filters.leadStatus !="assignToUser"?filters.leadStatus:null,
      filters.callStatus,
      filters.toDate,
      filters.fromDate,
      filters.phoneNumber,
      filters.dealerId,
      filters.leadStatus !="assignToUser"? filters.assignedTo:authService.getLoggedInUserId()! ,
      filters.received
    );
  }, [pageNumber, filters]);

  async function getLeads(
    leadStatus: string | null,
    callStatus: string | null,
    toDate: number | null,
    fromDate: number | null,
    phoneNumber: string | null,
    dealerId: string | null,
    userId: string | null,
    received: boolean| null
  ) {
    const [leads, totalCount, status] = await allUsedCarsService.getLeads(
    leadStatus,
      callStatus,
      fromDate,
      toDate,
      phoneNumber,
      pageNumber,
      pageSize,
      dealerId,
      userId,
      received
    );
    if (status == 200) {
      setLeads(leads as Lead[]);
      setTotalCount(totalCount);
    }
    setLoading(false);
  }

  async function addNewLead(lead: Lead) {
    setLeads((prev) => [lead, ...prev!]);
  }

  const handleOnClick = (lead: Lead) => {
    setSelectedLead(lead);
    setShow(true);
  };

  const handleSendWhatsappOnClick = (lead: Lead) => {
    setSelectedLead(lead);
    setShowWhatsappSend(true);
  };
  const handleAssignToClick = async (lead: Lead) => {
    setAssignToButtonLoading(true);
    const userOptions = await getUserOptions(
      authService.getLoggedInUserCompanyId()!,
      lead
    );

    setAssignToButtonLoading(false);
    setShowAssignToPopup(true);
  };

  const sendToBigDealer = async (slead: Lead) => {
  
    const [lead, status] =   await allUsedCarsService.sendToBigDealer(
      slead.leadId!
    );
  
    if (status == 200) {
      if (lead != null) {
    
        if (slead?.leadStatus != lead.leadStatus) {
     
          removeLeadFromList(lead);
        } else {
        
          updateLeadsData(lead);
        }
      }
     
    }
  };

  const assignToMe = async (slead: Lead) => {
  
    const [lead, status] =   await allUsedCarsService.assignUserToLead(
      slead.leadId!,
      authService.getLoggedInUserId()!,
      authService.getLoggedInUserCompleteName()!
    );
  
    if (status == 200) {
      if (lead != null) {
    
        if (slead?.leadStatus != lead.leadStatus) {
     
          removeLeadFromList(lead);
        } else {
        
          updateLeadsData(lead);
        }
      }
     
    }
  };

  const getAllUsers = async (companyId: String) => {
  const [users, status] = await companyService.getAssignToUsers(companyId);
  if (status == 200) {
    setUserOptions(users);
    return users;
  }
  setUserOptions([]);
}

  const getUserOptions = async (companyId: String, lead: Lead) => {
    const [users, status] = await companyService.getAssignToUsers(companyId);
    setLead(lead);
    if (status == 200) {
      setUserOptions(users);
      return users;
    }
    setUserOptions([]);
    return [];
  };

  const createNewLeadPopup = () => {
    setshowCreateNewPopup(true);
  };
  const filtersPresent = () => {
    return filters !== defaultFilters;
  };
  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const updateLeadsData = (lead: Lead | undefined | null) => {
    if (lead) {
      const indexToUpdate = leads!.findIndex(
        (item) => item.leadId === lead.leadId!
      );

      if (indexToUpdate !== -1) {
        leads![indexToUpdate] = { ...leads![indexToUpdate], ...lead };
      }
      setLeads(leads as Lead[]);
    }
  };

  const createNewLead = () => {};

  useEffect(() => {}, [show]);

  useEffect(() => {}, [JSON.stringify(leads)]);

  const renderItem = (item: Lead) => {
    return (
      <AntdList.Item className={"card3"}>
        <div>
          <div className="contentWrapper lead-card">
            <div className="titledate-div">
              <div className="company-info-wrapper">
                {item.name && <p className="user-msg">Name: {item.name}</p>}
                {item.phoneNumber && (
                  <p className="user-msg">Phone: {item.phoneNumber}</p>
                )}
              </div>
              <div style={{ textAlign: "right" }}>
                <p className="user-msg">Lead Status: {item.leadStatus}</p>
                {item.leadCallStatus && (
                  <p className="user-msg">Call Status: {item.leadCallStatus}</p>
                )}

{item.lastQueryTime && (
                 <><span className="user-date">Last queried At </span> <Text className="user-date">
                    {dateFormat(
                      new Date(item.lastQueryTime! * 1000),
                      "dd-mmm hh:MM TT"
                    )}
                  </Text><br/></>
                )}
                {item.updatedAt && (
                 <><span className="user-date">Updated At </span> <Text className="user-date">
                    {dateFormat(
                      new Date(item.updatedAt! * 1000),
                      "dd-mmm hh:MM TT"
                    )}
                  </Text><br/></>
                )}
                {item.createdAt && (
                   <> <span className="user-date">Created At </span>
                  <Text className="user-date">
                    {dateFormat(
                      new Date(item.createdAt! * 1000),
                      "dd-mmm hh:MM TT"
                    )}
                  </Text></>
                )}
                {item.assignToUser&& item.assignToUser.userName && 
                <><br/><span className="user-date">Assign To: {item.assignToUser?.userName}</span></>}
              </div>
            </div>
            <Collapse defaultActiveKey={["0"]}>
              {item.enquiries &&
                item.enquiries.map((inquiry, indx) => (
                  <Panel
                    header={
                      inquiry.dealerId
                        ? inquiry.dealerId
                        : inquiry.additionalInfo
                        ? inquiry.additionalInfo
                        : "Car Info"
                    }
                    key={indx}
                  >
                    <div>
                      <div className="contentWrapper ">
                        <div className="message-div">
                          {inquiry.location && (
                            <p className="user-msg">
                              Location: {inquiry.location}
                            </p>
                          )}
                          {inquiry.dealerId && (
                            <p className="user-msg">
                              DealerId: {inquiry.dealerId}
                            </p>
                          )}
                           {inquiry.leadType && (
                            <p className="user-msg">
                              Lead Type: {inquiry.leadType}
                            </p>
                          )}
                          {inquiry.dealerName && (
                            <p className="user-msg">
                              DealerName: {inquiry.dealerName}
                            </p>
                          )}
                          {inquiry.carInfo && (
                            <p className="user-msg">
                              CarInfo: {inquiry.carInfo}
                            </p>
                          )}
                          {inquiry.carId && (
                            <p className="user-msg">CarId: {inquiry.carId}</p>
                          )}
                          {inquiry.carType && (
                            <p className="user-msg">
                              CarType: {inquiry.carType}
                            </p>
                          )}
                          <br />
                          {inquiry.additionalInfo && (
                            <p className="user-msg">
                              AdditionalInfo: {inquiry.additionalInfo}
                            </p>
                          )}
                          <br />
                          {inquiry.carId && (
                            <p className="user-msg">
                              URL:{" "}
                              <a
                                target="_blank"
                                href={`https://www.allusedcars.in/single/${inquiry.carId}`}
                              >
                                Link
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Panel>
                ))}
            </Collapse>
            {item.customerInteractions && (
                 <Collapse defaultActiveKey={["1"]}>
              <Panel   key={0} header={"Chats"}>
             
                {item.customerInteractions &&
                item.customerInteractions.map((interaction, indx) =>  (
                  <div className="chatcontainer__innerdiv" key={indx}>
                  <li className={interaction.incoming ? 'message left' : 'message right'}>
                
                  <p className="messageWrp" >{interaction?.content?.replace(/\n/g, "<br />")} </p> 
              {interaction.createdAt &&<h4>{dateFormat(new Date(interaction.createdAt! * 1000), "dd-mmm hh:MM TT")}</h4>}
              </li>
                
                </div>
                  ))}
            
              </Panel></Collapse>
            )}
            {(item.comments || item.leadDropReason) && (
              <div className="message-div-lead lead-card ">
                {item.comments && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        "Comments:<br/>" +
                        item.comments!.replace(/\n/g, "<br />"),
                    }}
                  ></p>
                  // <p className="user-msg">Comments: {item.comments}</p>
                )}
                <br />
                {item.leadDropReason && (
                  <p className="user-msg">Drop reason: {item.leadDropReason}</p>
                )}
                <br />
                {item.firstCallTime && (
                  <p className="user-msg">
                    {" "}
                    <Text>
                      First call time :{" "}
                      {dateFormat(
                        new Date(item.firstCallTime! * 1000),
                        "dd-mmm hh:MM TT"
                      )}
                    </Text>
                  </p>
                )}
                {item.secondCallTime && (
                  <p className="user-msg">
                    {" "}
                    <Text>
                      Second call time :{" "}
                      {dateFormat(
                        new Date(item.secondCallTime! * 1000),
                        "dd-mmm hh:MM TT"
                      )}
                    </Text>
                  </p>
                )}
                {item.thirdCallTime && (
                  <p className="user-msg">
                    {" "}
                    <Text>
                      Third call time :{" "}
                      {dateFormat(
                        new Date(item.thirdCallTime! * 1000),
                        "dd-mmm hh:MM TT"
                      )}
                    </Text>
                  </p>
                )}
              </div>
            )}
            <div>
              <Button
                className="filtersApplybtn"
                onClick={() => {
                  handleOnClick(item);
                }}
              >
                Change Status
              </Button>
            
              <Button
                className="filtersApplybtn"
                onClick={() => {
                //  setSelectedLead(item);
                  handleAssignToClick(item);
                }}
              >
                Assign To
              </Button>
              <Button
                className="filtersApplybtn"
                onClick={() => {
                 
                  assignToMe(item);
                }}
              >
                Assign To Me
              </Button>
              <Button
                className="filtersApplybtn"
                onClick={() => {
                 
                  handleSendWhatsappOnClick(item);
                }}
              >
                Send Whatsapp
              </Button>
              
            </div>
          </div>
        </div>
      </AntdList.Item>
    );
  };

  const renderEnquiryItem = (item: LeadEnquiry) => {
    return (
      <Panel header=" ff" key="1">
        <div>
          <div className="contentWrapper lead-card">
            <div className="message-div">
              {item.location && (
                <p className="user-msg">Location: {item.location}</p>
              )}
              {item.dealerId && (
                <p className="user-msg">DealerId: {item.dealerId}</p>
              )}
              {item.dealerName && (
                <p className="user-msg">DealerName: {item.dealerName}</p>
              )}
              {item.carInfo && (
                <p className="user-msg">CarInfo: {item.carInfo}</p>
              )}
              {item.carId && <p className="user-msg">CarId: {item.carId}</p>}
              {item.carType && (
                <p className="user-msg">CarType: {item.carType}</p>
              )}
              <br />
              {item.additionalInfo && (
                <p className="user-msg">
                  AdditionalInfo: {item.additionalInfo}
                </p>
              )}
              <br />
              {item.carId && (
                <p className="user-msg">
                  URL:{" "}
                  <a
                    target="_blank"
                    href={`https://www.allusedcars.in/single/${item.carId}`}
                  >
                    Link
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </Panel>
    );
  };
  const removeLeadFromList = (lead: Lead) => {
    const indexToUpdate = leads!.findIndex(
      (item) => item.leadId === lead.leadId!
    );
    leads!.splice(indexToUpdate, 1);
  };
  return (
    <PageHeader className="chatheaderwrapper" ghost={false}>
      <ToastContainer />

      <div className="inbox-wrapper">
        <div className="leadlist">
          <div className="inbox-header">
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                {" "}
                <h3
                  style={{ margin: 0, marginLeft: "10px", cursor: "pointer" }}
                  onClick={async () => {
                    await getAllUsers( authService.getLoggedInUserCompanyId()!)
                    setShowFiltersPopup((prev) => !prev)
                  }}
                >
                  <Icons.FilterOutlined /> Filters
                </h3>
                {filtersPresent() && (
                  <p
                    style={{
                      margin: 0,
                      textAlign: "right",
                      fontSize: "10px",
                      cursor: "pointer",
                    }}
                    onClick={resetFilters}
                  >
                    <Icons.CloseOutlined /> Clear filters
                  </p>
                )}
                {totalCount && (
                  <p className="totalResults">
                    ( Total results: {totalCount} )
                  </p>
                )}
              </div>
              <Button
                className="filtersApplybtn"
                style={{ width: "100px" }}
                onClick={() => {
                  createNewLeadPopup();
                }}
              >
                Add Lead
              </Button>
            </div>

            <LeadsFiltersPopup
              showFilterspopup={showFiltersPopup}
              closeFiltersPopup={() => setShowFiltersPopup(false)}
              filters={filters}
              userOptions={userOptions}
              changeFilters={setFilters}
            />
            <CreateNewLeadPopup
              callback={(lead: Lead | null) => {
                setshowCreateNewPopup(false);
                // getLeads(
                //   filters.leadStatus,
                //   filters.callStatus,
                //   filters.toDate,
                //   filters.fromDate,
                //   filters.phoneNumber
                // );
                if (lead) {
                  addNewLead(lead!);
                }
              }}
              show={showCreateNewPopup}
            />
            <LeadStatusChangePopup
              lead={selectedLead}
              callback={(lead: Lead) => {
                if (lead != null) {
                  if (selectedLead?.leadStatus != lead.leadStatus) {
                    removeLeadFromList(lead);
                  } else {
                    updateLeadsData(lead);
                  }
                }
                setShow(false);
                setSelectedLead(null);

              }}
              show={show}
            />
             <SendWhatsappPopup
              lead={selectedLead}
              callback={(lead: Lead) => {
                if (lead != null) {
                    updateLeadsData(lead);
                }
                setShowWhatsappSend(false);
                setSelectedLead(null);

              }}
              show={showWhatsappSend}
            />

            <LeadAssignToPopup
              userOptions={userOptions}
              slead={slead}
              callback={(ulead:Lead) => {
              
                if (ulead != null) {
                  if (slead?.leadStatus != ulead.leadStatus) {
                 
                    removeLeadFromList(ulead);
                  } else {
                  
                    updateLeadsData(ulead);
                  }
                }
                setShowAssignToPopup(false);
              
              }}
              show={showAssignToPopup}
            />
            <div className="tabs">
              <div
                className={`tab ${
                  filters.leadStatus === "NEW" ? "active" : ""
                }`}
                onClick={() => {
                  setFilters({ ...filters, leadStatus: "NEW" });
                  getLeads(
                    "NEW",
                    filters.callStatus,
                    filters.toDate,
                    filters.fromDate,
                    filters.phoneNumber,
                    filters.dealerId,
                    filters.assignedTo,
                    filters.received
                  );
                }}
              >
                New
              </div>

              <div
                className={`tab ${
                  filters.leadStatus === "ASSIGNED" ? "active" : ""
                }`}
                onClick={() => {
                  setFilters({ ...filters, leadStatus: "ASSIGNED" });
                  getLeads(
                    "ASSIGNED",
                    filters.callStatus,
                    filters.toDate,
                    filters.fromDate,
                    filters.phoneNumber,
                    filters.dealerId,
                    filters.assignedTo,
                    filters.received
                  );
                }}
              >
                Assigned
              </div>
              <div
                className={`tab ${
                  filters.leadStatus === "FOLLOW_UP" ? "active" : ""
                }`}
                onClick={() => {
                  setFilters({ ...filters, leadStatus: "FOLLOW_UP" });
                  getLeads(
                    "FOLLOW_UP",
                    filters.callStatus,
                    filters.toDate,
                    filters.fromDate,
                    filters.phoneNumber,
                    filters.dealerId,
                    filters.assignedTo,
                    filters.received
                  );
                }}
              >
                FOLLOW UP
              </div>
              
              <div
                className={`tab ${
                  filters.leadStatus === "TEST_DRIVE_SCHEDULED" ? "active" : ""
                }`}
                onClick={() => {
                  setFilters({ ...filters, leadStatus: "TEST_DRIVE_SCHEDULED" });
                  getLeads(
                    "TEST_DRIVE_SCHEDULED",
                    filters.callStatus,
                    filters.toDate,
                    filters.fromDate,
                    filters.phoneNumber,
                    filters.dealerId,
                    filters.assignedTo,
                    filters.received
                  );
                }}
              >
                TEST DRIVE SCHEDULED
              </div>
              <div
                className={`tab ${
                  filters.leadStatus === "TEST_DRIVE_DONE" ? "active" : ""
                }`}
                onClick={() => {
                  setFilters({ ...filters, leadStatus: "TEST_DRIVE_DONE" });
                  getLeads(
                    "TEST_DRIVE_DONE",
                    filters.callStatus,
                    filters.toDate,
                    filters.fromDate,
                    filters.phoneNumber,
                    filters.dealerId,
                    filters.assignedTo,
                    filters.received
                  );
                }}
              >
                TEST DRIVE DONE
              </div>
              <div
                className={`tab ${filters.leadStatus == null ? "active" : ""} `}
                onClick={() => {
                  setFilters({ ...filters, leadStatus: null });
                  getLeads(
                    null,
                    filters.callStatus,
                    filters.toDate,
                    filters.fromDate,
                    filters.phoneNumber,
                    filters.dealerId,
                    filters.assignedTo,
                    filters.received
                  );
                }}
              >
                ALL
              </div>
   
              <div
                className={`tab ${
                  filters.leadStatus == "assignToUser" ? "active" : ""
                } `}
                onClick={() => {
                  setFilters({ ...filters, leadStatus:"assignToUser" });
                  getLeads(
                    "",
                    filters.callStatus,
                    filters.toDate,
                    filters.fromDate,
                    filters.phoneNumber,
                    filters.dealerId,
                    authService.getLoggedInUserId()!,
                    filters.received
                  );
                }}
              >
                Assigned To Me
              </div>
            </div>
          </div>

          <AntdList
            className="listWrapper"
            loading={isLoading}
            dataSource={leads}
            renderItem={renderItem}
          />
          <div className="pagination-list">
            {pageNumber > 0 ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setPageNumber((prev) => prev - 1)}
              >
                <LeftOutlined />
              </div>
            ) : (
              <div></div>
            )}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setPageNumber((prev) => prev + 1)}
            >
              <RightOutlined />
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
};
