import { useEffect, useRef ,useState} from "react";
import { useLogin } from "@pankod/refine-core";
import { AntdLayout,Button ,useModal,
    Modal,
    Input,UrlField} from "@pankod/refine-antd"
import { defaultConfig } from "config";
import { useNavigate } from 'react-router-dom';
import companyService from "services/companyService";
import { notifyError } from "components/Notification";
import Lottie from 'react-lottie';
import ProgressAnim from "../components/progressingmagiclink.json"
import authService from "services/authService";
import { parseJwt } from "utils/parse-jwt";
import OtpInput from 'react-otp-input';
const clientId = defaultConfig.googleClientId;

export const Login: React.FC = () => {
    const { mutate: login } = useLogin<CredentialResponse>(); // TODO: tpype will be added
    // const { mutate: magiclinklogin } = useLogin<CredentialResponse>(); // TODO: tpype will be added
    const navigate = useNavigate()
    const [magiclinkEmail, setMagicLinkEmail] = useState<string>("")
    const { modalProps, show, close } = useModal();
    const { modalProps:otpModalProps, show:otpShow, close:otpClose } = useModal();
    const [showprogress, setShowProgress] = useState<Boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [otp, setOtp] = useState<string>("")
    const [showOtp, setShowOtp] = useState<Boolean>(true)
    const GoogleButton = (): JSX.Element => {
        const divRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (
                typeof window === "undefined" ||
                !window.google ||
                !divRef.current
            ) {
                console.log(typeof window,!window.google,!divRef.current)
                return;
            }

            try {
                window.google.accounts.id.initialize({
                    ux_mode: "popup",
                    client_id: clientId,
                    callback: async (res) => {
                        if (res.credential) {
                            login(res);
                        }
                    },
                });
                window.google.accounts.id.renderButton(divRef.current, {
                    theme: "filled_blue",
                    size: "medium",
                    type: "standard",
                });
            } catch (error) {
                console.log("Error loading popup",error);
            }
        }, [clientId, window.google, divRef.current]);

        return <div ref={divRef} />;
    };
    const generateMagicLink = async ()=>{
        const [res,status] = await companyService.generateMagicLink(magiclinkEmail)
        if(status===200){
            localStorage.setItem("magiclink-requestId1",res)
            setShowProgress(true)
            pollingMagicLink()
        }
        else{
            notifyError("Something went wrong")
        }
    }


    const cardStyle = { 
        width: "360px", 
        height: "192px", 
        borderRadius: "16px", 
        marginRight: "24px", 
        boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
        maxWidth: "200px", 
        margin: "auto" ,display: "flex",
                alignItems: "center",
                flexDirection: "column",
                alignContent: "flex-end"
     }

     useEffect(()=>{
        if(localStorage.getItem("magiclink-requestId1") && !localStorage.getItem("token")){
            pollingMagicLink()
        }
     },[])

    const pollingMagicLink = async ()=>{
        const [res,status] = await companyService.getMagicLinkStatus()
        if(res.status=="SUCCESS"){
            const benamJWT = parseJwt(res.token)
            authService.setScope(benamJWT);
            //if user is not a valid user
            if (!authService.isSuperAdmin() && !authService.isCompanyUser() && !authService.isCompanyAdmin()) {
                authService.clearAll();
            }
            else {
                localStorage.setItem("token", `${res.token}`);
            }
            navigate("/")
            window.location.reload()
        }
    }

    const sendOtp = async ()=>{
        try {
            const [res,status] = await companyService.generateOTP(phoneNumber)
            if(status===200){
                notifyError("OTP sent successfully")
                setTimeout(()=>{
                    setShowOtp(true)
                }
                ,1000)
            }
        } catch (error) {
            setTimeout(()=>{
                setShowOtp(true)
            }
            ,1000)
            notifyError("Something went wrong")
        }
    }

    const resendOtp = async ()=>{
        try {
            const [res,status] = await companyService.resendOtp(phoneNumber)
            if(status===200){
                notifyError("OTP sent successfully")
            }
            else{
                notifyError("Something went wrong")
            }
        } catch (error) {
        
            notifyError("Something went wrong")
        }
        
    }

    const verifyOtp = async ()=>{
        try {
            const [res,status] = await companyService.verifyOtp(phoneNumber,otp)
            if(status===200 && res.data.verified){
                const benamJWT = parseJwt(res.data.token)
                authService.setScope(benamJWT);
                //if user is not a valid user
                if (!authService.isSuperAdmin() && !authService.isCompanyUser() && !authService.isCompanyAdmin()) {
                    authService.clearAll();
                }
                else {
                    localStorage.setItem("token", `${res.data.token}`);
                }
                navigate("/")
                window.location.reload()
            }
            else{
                notifyError("Invalid Otp")
            }
        } catch (error) {
            notifyError("Invalid Otp or you are not assigned to any company")
            
        }
        
    }

    return (
        <AntdLayout
            style={{
                background: `radial-gradient(10% 10% at 0% 50%, #ffffff 0%, #ffffff 100%)`,
                backgroundSize: "cover",
            }}
        >
            
            <div style={{height: "700px", "overflow": "scroll"}} >
               
                <div className="login-box" >
                    <div className="login-image" >
                        <img src="/assets/auc.png" className="benam-logo-img"/>
                        <img src="/assets/benam.png" className="benam-chat-img" />
                        <h2>Customer Care Application</h2>
                        <p>Purposely built for solving your needs</p>
                    </div>
                    <div className="login-btn-box">
                        <div style={{ marginBottom: "15px" }}>
                            <h1 style={{margin:0}}>Welcome to Benam</h1>
                            <p style={{color:'darkgray'}}>You are just one click away</p>
                        </div>
                        <GoogleButton />
                        <p style={{color:"#cccccc", marginTop:"1em"}} >Or</p>
                        <Button onClick={()=>show()} >Login via Magic Link</Button>
                        <p style={{color:"#cccccc", marginTop:"1em"}} >Or</p>
                        <Button onClick={()=>otpShow()} >Login via OTP</Button>
                        <p style={{color:'darkgray', marginTop:"1em"}}>Don't have an account?</p>
                                <UrlField  target={"_blank"} value={"https://www.benam.me/onboard"} >Sign up Now</UrlField>

                        <Modal maskClosable={false} closable={false} {...modalProps} cancelButtonProps={{style:{display:"none"}}} okButtonProps={{style:{display:"none"}}} >
                            <div style={{textAlign:"center",marginTop:"20px"}} >
                                <Input placeholder="Enter your email" onChange={(e)=> setMagicLinkEmail(e.target.value)} />
                                <Button className="magicLink-btn" onClick={()=>generateMagicLink()} >Generate Magic Link</Button>
                              
                                {showprogress && <p>We have sent you a verification email. Please do not refresh the page.</p>}
                                {showprogress && <Lottie options={{
                                    loop: true,
                                    autoplay: true, 
                                    animationData: ProgressAnim,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                    }}
                                    height={200}
                                    width={200}
                                    isStopped={false}
                                    isPaused={false}
                                    />}
                            </div>
                            
                        </Modal>
                        <Modal {...otpModalProps} okButtonProps={{style:{display:"none"}}} >
                            <div style={{textAlign:"center",marginTop:"20px"}} >
                                <Input placeholder="Enter your phonenumber" style={{width:"80%", textAlign:"center"}} onChange={(e)=> setPhoneNumber(e.target.value)} />
                                {!showOtp && <Button className="magicLink-btn" onClick={sendOtp} >Send Otp</Button>}
                                {showOtp && <div>
                                    <p style={{marginTop:"1em"}}>Enter your 6-digit code here</p> 
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        containerStyle={"otp-input"}
                                        renderSeparator={<span>-</span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <Button className="magicLink-btn" onClick={()=>verifyOtp()} >Verify Otp</Button>
                                    <p>Didn't Recieve an OTP ? <span style={{color:'black', fontWeight:'bold',cursor:"pointer"}} onClick={()=>resendOtp()} > Resend OTP</span> </p>
                                    {/* <p onClick={resendOtp} >Resend Otp</p> */}
                                </div>
                                }
                            </div>
                            
                        </Modal>
                    </div>
                                  
                    <div>
                    <h2> For Shopify Merchants</h2>
       <p> 
        Follow the following steps to enable the Benam widget on your store 
        1. Go to admin page of your store 
        2. Go to  Online Store  Themes   
        3. Click on Customize button 
        4. Click on App Embeds menu in left menu bar 
        5. Toggle button to enable Benam Chat Widget
        
        
        
        
        
        
        </p>

                    </div>
                                    </div>

                                 
            </div>
      

        </AntdLayout>
    );
};