import { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Button, DatePicker, Select } from "@pankod/refine-antd";
import dayjs from "dayjs";
import { type } from "os";
import Switch from "react-switch";
import masterData from "utils/masterData";
interface LeadsFiltersPopupProps {
  showFilterspopup: boolean;
  filters: any;
  changeFilters: (filters: any) => void;
  closeFiltersPopup: () => void;
  userOptions:any;
}

interface StatusFilter {
  filterName: string;
}
const callStatusOptions = [
  {
    label: "Connected",
    value: "Connected",
  },
  {
    label: "Busy",
    value: "Busy",
  },
  {
    label: "Ringing Not Responding",
    value: "Ringing_Not_Responding",
  },
  {
    label: "Wrong Number",
    value: "Wrong_Number",
  },
  {
    label: "Invalid Number",
    value: "Invalid_Number",
  },
  {
    label: "Number Not Available",
    value: "Number_Not_Available",
  },
  {
    label: "Switched Off",
    value: "Switched_Off",
  },
  {
    label: "Hung Up",
    value: "Hung_Up",
  },
  {
    label: "Chat On WhatsApp",
    value: "Chat_On_WhatsApp",
  },
  {
    label: "Other",
    value: "Other",
  },
];
const leadStatusOptions = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "NEW",
    value: "NEW",
  },
  {
    label: "ASSIGNED",
    value: "ASSIGNED",
  },
  {
    label: "Cold",
    value: "Cold",
  },
  {
    label: "Warm",
    value: "Warm",
  },
  {
    label: "Hot",
    value: "Hot",
  },
  {
    label: "ON HOLD",
    value: "ON_HOLD",
  },
  {
    label: "TEST DRIVE SCHEDULED",
    value: "TEST_DRIVE_SCHEDULED",
  },
  {
    label: "DROPPED",
    value: "DROPPED",
  },
  {
    label: "DUPLICATE",
    value: "DUPLICATE",
  },
  {
    label: "TEST DRIVE DONE",
    value: "TEST_DRIVE_DONE",
  },
  {
    label: "SOLD",
    value: "SOLD",
  },
];

const LeadsFiltersPopup = (props: LeadsFiltersPopupProps) => {
  const { showFilterspopup } = props;
  const [callStatus, setCallStatus] = useState("ALL");
  const [leadStatus, setLeadStatus] = useState("ALL");
  const [fromDate, setFromDate] = useState<number | null>(null);
  const [toDate, setToDate] = useState<number | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [dealerId, setDealerId] = useState<string | null>(null);
  const [received, setReceived] = useState<boolean | null>(null);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (props.filters) {
      setCallStatus(props.filters?.callStatus ?? "ALL");
      setLeadStatus(props.filters?.leadStatus ?? "ALL");
      setFromDate(
        props.filters?.fromDate != null ? props.filters?.fromDate * 1000 : null
      );
      setToDate(
        props.filters?.toDate != null ? props.filters?.toDate * 1000 : null
      );
      setPhoneNumber(props.filters?.phoneNumber);
      setDealerId(props.filters?.dealerId);
      setReceived(props.filters?.received);
    }
  }, [props.filters]);

  return (
    <div
      className="filterspopupWrp"
      style={{ display: showFilterspopup ? "block" : "none" }}
    >
    
      <div className="seperate-filter-wrp">
        <h4>Leads Status</h4>
        <Select
          style={{ width: "100%" }}
          value={leadStatus}
          onChange={(val) => {
            setLeadStatus(val);
          }}
          options={masterData.getLeadStatusData()}
        />
      </div>
      <div className="seperate-filter-wrp">
        <h4>Call Status</h4>
        <Select
          style={{ width: "100%" }}
          value={callStatus}
          onChange={(val) => {
            setCallStatus(val);
          }}
          options={masterData.getCallStatusData()}
        />
      </div>
      <div className="seperate-filter-wrp">
        <h4>From Date</h4>
        <DatePicker
          style={{ width: "100%" }}
          onChange={(val) => {
            const selectedDayjs = dayjs(val);
            const startOfDay = selectedDayjs.startOf("day");
            const millisecondsSinceEpoch = startOfDay.valueOf(); // dayjs provides .valueOf() to get milliseconds

            setFromDate(millisecondsSinceEpoch);
          }}
          value={fromDate == null ? null : dayjs(fromDate)}
          defaultValue={props.filters?.fromDate}
        />
      </div>
      <div className="seperate-filter-wrp">
        <h4>To Date</h4>
        <DatePicker
          style={{ width: "100%" }}
          onChange={(val) => {
            const selectedDayjs = dayjs(val);
            const startOfDay = selectedDayjs.endOf("day");
            const millisecondsSinceEpoch = startOfDay.valueOf();

            setToDate(millisecondsSinceEpoch);
          }}
          value={toDate == null ? null : dayjs(toDate)}
          defaultValue={props.filters?.toDate}
        />
      </div>
      <div className="seperate-filter-wrp">
        <h4>Phone Number</h4>
        <input
          type="text"
          className="usertraxnform__field" 
          placeholder="Enter Phone Number"
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          value={phoneNumber ?? ""}
          defaultValue={props.filters?.phoneNumber}
        />
      </div>
      <div className="seperate-filter-wrp">
            <h4>Assigned To</h4>
            <Select
              style={{ width: "100%" }}
              value={user}
              onChange={(val) => {
                setUser(val);
              }}
              defaultValue={props.userOptions[0]?.alias}
              options={props.userOptions.map((u: any) => {
                return { value: u.alias, label: u.alias };
              })}
            />
          </div>
      <div className="seperate-filter-wrp">
        <h4>Received?</h4>
        <Switch onColor="#fec627" handleDiameter={10} width={50} height={20} 
        checked={props.filters.received} onChange={(chckd:boolean)=>{
                    // setCallbackRequest(chckd)
                    setReceived(chckd)
                    props.filters.received = chckd;
                    // getStatusChats(chatType,chckd)
                    }} />
      </div>
      <div className="seperate-filter-wrp">
        <h4>DealerId</h4>
        <input
          type="text"
          className="usertraxnform__field" 
          placeholder="Enter dealerId"
          onChange={(e) => {
            setDealerId(e.target.value);
          }}
          value={dealerId ?? ""}
          defaultValue={props.filters?.dealerId}
        />
      </div>
      <Button
        className="filtersApplybtn"
        onClick={() => {
          // print types of fromDate and toDate
          props.changeFilters({
            callStatus: callStatus == "ALL" ? null : callStatus,
            leadStatus: leadStatus == "ALL" ? null : leadStatus,

            fromDate: fromDate != null ? (fromDate / 1000).toFixed(0) : null,
            toDate: toDate != null ? (toDate / 1000).toFixed(0) : null,
            phoneNumber,
            dealerId,
            received,
            assignedTo:user?props.userOptions.find((u: any) => u.alias === user)?.userId:null
          });
          props.closeFiltersPopup();
        }}
      >
        Apply
      </Button>
      <Button className="filtersApplybtn" onClick={()=>{  props.closeFiltersPopup();}}>
          Cancel
        </Button>
    </div>
  );
};

export default LeadsFiltersPopup;
