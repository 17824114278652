import { IMessage } from "interfaces"

export const isDuplicate = (messages: IMessage[] | undefined, msg: IMessage) => {
  //  console.log("check");
    if (messages) {
        const message = messages.find(a => { a.messageId == msg.messageId });
     //   console.log("ye" + message)
        return message ? true : false;
    }
    return false;
}

export const updateUnreadMsg = (msg: IMessage) => {
   // console.log("upload unread called")
    const unreadMsgs = localStorage.getItem("getUnreadMsgs");
    if (unreadMsgs) {
     //   console.log("upload unread called1")
        const mapUnReadCounts: Map<string, number> = new Map(JSON.parse(unreadMsgs));
        if (mapUnReadCounts.get(msg.userId! + "--" + msg.companyId!)) {
            mapUnReadCounts.set(msg.userId! + "--" + msg.companyId!, mapUnReadCounts.get(msg.userId!)! + 1);
        }
        else {
            mapUnReadCounts.set(msg.userId! + "--" + msg.companyId!, 1);
        }
        localStorage.setItem("getUnreadMsgs", JSON.stringify(Array.from(mapUnReadCounts)));
    }
    else {
      //  console.log("upload unread called2")
        let mapUnReadCounts: Map<string, number> = new Map();
        mapUnReadCounts.set(msg.userId! + "--" + msg.companyId!, 1);
       // console.log(JSON.stringify(Array.from(mapUnReadCounts)))
        localStorage.setItem("getUnreadMsgs", JSON.stringify(Array.from(mapUnReadCounts)));
    }

}

export const clearUnreadMsg = (userId:string, companyId:string) => {
   // console.log("clear called")
    const unreadMsgs = localStorage.getItem("getUnreadMsgs");
    if (unreadMsgs) {
        const mapUnReadCounts: Map<string, number> =  new Map(JSON.parse(unreadMsgs));

        if (mapUnReadCounts.get(userId + "--"+ companyId)) {
            mapUnReadCounts.delete(userId + "--"+ companyId)
            localStorage.setItem("getUnreadMsgs", JSON.stringify(Array.from(mapUnReadCounts)));
        }
    }
}

export const getUnreadMsg = () => {
    const unreadMsgs = localStorage.getItem("getUnreadMsgs");
    if (unreadMsgs) {
        const mapUnReadCounts: Map<string, number> =  new Map(JSON.parse(unreadMsgs));
        return mapUnReadCounts;
    }
}