import {
  IResourceComponentsProps,
  useMany,
  useNavigation,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  ShowButton,
  CreateButton,
  Button,
  AntdList,
  NumberField,
  PageHeader,
  Typography,
  Pagination,
  Card,
  Avatar,
  Select,
  Icons,
} from "@pankod/refine-antd";
//@ts-ignore
import Switch from "react-switch";
import { IMessage, INewPost, IPost, IUserCompany } from "interfaces";
import { useEffect, useState, useRef } from "react";
import companyService from "services/companyService";
import dateFormat from "dateformat";

import { defaultConfig } from "config";
import authService from "services/authService";
import { getUnreadMsg, updateUnreadMsg } from "utils/messageManager";
import RouterProvider from "@pankod/refine-react-router-v6";

import { useNavigate } from "react-router-dom";
import Peer, { PeerConnectOption } from "peerjs";
import Calling from "components/Calling";
import AcceptReject from "components/AcceptReject";
import { notifySuccess } from "components/Notification";
import { ToastContainer } from "react-toastify";
import FiltersPopup from "components/FiltersPopup";

export const PostsList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const {
    PhoneOutlined,
    LeftOutlined,
    RightOutlined,
    FilterOutlined,
    CloseOutlined,
    CheckOutlined,
  } = Icons;
  const [unreadMsgMap, setUnReadMap] = useState<Map<string, number>>();
  const [usercompanies, setUsrCompany] = useState<INewPost[]>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [callbackRequest, setCallbackRequest] = useState(false);
  const [filters, setFilters] = useState({
    chatStatus: "ALL",
    callbackRequest: false,
    response: [],
  });
  const [chatType, setChatType] = useState("ALL");
  const [pageNumber, setPageNumber] = useState(0);
  const [showFilterspopup, setShowFiltersPopup] = useState(false);
  const pageSize = 50;
  const userCompanyId = RouterProvider.useParams().id;
  const audioRef = useRef(null);
  let myArray;
  let userId: string | null = null;
  let companyId: string | null = null;
  if (userCompanyId) {
    myArray = userCompanyId.split("--");
    userId = myArray[0];
    companyId = myArray[1];
  }
  const [expandedPostId, setExpandedPostId] = useState("");
  const [deeplink, setDeeplink] = useState("");

  useEffect(() => {
    async function getuserscompanyInfo() {
      const [userscompany, status] = await companyService.getPosts();
      console.log(userscompany);
      const [feedbackPosts, feedbackPostStatus] =
        await companyService.getFeebackPosts();
      if (status == 200) {
        if (feedbackPostStatus == 200) {
          setUsrCompany([
            ...(userscompany as INewPost[]),
            ...(feedbackPosts as INewPost[]),
          ]);
        } else {
          setUsrCompany(userscompany as INewPost[]);
        }
      } else {
        if (feedbackPostStatus == 200) {
          setUsrCompany([...(feedbackPosts as INewPost[])]);
        }
      }
      setLoading(false);
    }
    getuserscompanyInfo();
  }, [pageNumber]);

  const approveRejectPost = async (postId: String, approve: Boolean) => {
    console.log("HI");
    const [response, status] = await companyService.approvePost(
      postId,
      approve,
      (
        document.getElementsByName(`${postId}`)[0] as HTMLInputElement
      ).value.toString()
    );
    console.log(response, status);
    if (status == 200) {
      setUsrCompany(usercompanies?.filter((item) => !(item.postId == postId)));
    }
  };

  useEffect(() => {}, [usercompanies]);
  const renderItem = (item: INewPost) => {
    return (
      <AntdList.Item>
        <div className="post-card">
          <div>
            <Avatar
              size={45}
              src={
                item.postType == "FEEDBACK"
                  ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk91tUrSwE9xK8Bz-bIeEz3mVfjjMALIMJwPdgxXM&s"
                  : item.associatedCompanies == null ||
                    item.associatedCompanies[0] == null ||
                    item.associatedCompanies[0].logo == null
                  ? "/assets/auc.png"
                  : item.associatedCompanies[0].logo
              }
            />
          </div>
          <div className="contentWrapper">
            <div className="titledate-div">
              <div className="company-info-wrapper">
                <p className="company-user">{item.title}</p>
                <p className="company-title">{item.category}</p>
              </div>
              <div style={{ textAlign: "right" }}>
                {item.createdAt && (
                  <Text className="user-date">
                    {dateFormat(
                      new Date(item.createdAt! * 1000),
                      "dd-mmm hh:MM TT"
                    )}
                  </Text>
                )}
              </div>
            </div>
            <div>
              <p style={{ whiteSpace: "pre-wrap" }}>
                {expandedPostId == item.postId
                  ? item.description
                  : item.description.slice(0, 40)}
                <span
                  style={{
                    display: item.description.length < 40 ? "none" : "block",
                  }}
                  onClick={() => {
                    if (expandedPostId == item.postId) {
                      setExpandedPostId("");
                    } else {
                      setExpandedPostId(item.postId);
                    }
                  }}
                  className="read-or-hide"
                >
                  {expandedPostId != item.postId
                    ? "...read more"
                    : " show less"}
                </span>
              </p>
              <input
                className="post-input"
                name={item.postId}
                placeholder="Enter Deeplink"
              ></input>
              <div className="approve-reject-div">
                {/* {unreadMsgMap?.get(item.userId) && <div className="unreadMsgMadiv-msg">{"1"}</div>} */}

                <Button
                  className="approve-reject-btn"
                  onClick={() => {
                    approveRejectPost(item.postId, true);
                  }}
                >
                  <CheckOutlined />
                  Approve
                </Button>
                <Button
                  className="approve-reject-btn"
                  onClick={() => {
                    approveRejectPost(item.postId, false);
                  }}
                >
                  <CheckOutlined />
                  Reject
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <hr/> */}
      </AntdList.Item>
    );
  };
  return (
    <PageHeader className="chatheaderwrapper" ghost={false}>
      <div className="inbox-wrapper">
        <div
          className={
            userCompanyId ? `companylist m-companylist` : "companylist"
          }
        >
          <AntdList
            className="listWrapper"
            loading={isLoading}
            dataSource={usercompanies}
            renderItem={renderItem}
          />
          <div className="pagination-list">
            {pageNumber > 0 ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setPageNumber((prev) => prev - 1)}
              >
                <LeftOutlined />
              </div>
            ) : (
              <div></div>
            )}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setPageNumber((prev) => prev + 1)}
            >
              <RightOutlined />
            </div>
          </div>
        </div>

        <div
          className={userCompanyId ? `chatlist m-chatlist` : "chatlist"}
        ></div>
      </div>
    </PageHeader>
  );
};
