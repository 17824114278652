import { IResourceComponentsProps, useNotification, useRouterContext } from "@pankod/refine-core";

import { Show,Icons, Typography, MarkdownField, Space, PageHeader, AntdList, Input, Form, Button,Dropdown,DropDownProps, Select } from "@pankod/refine-antd";

import { ICompany, IMessage, IUserCompany } from "interfaces";
import { useEffect, useState,useRef } from "react";
import companyService from "services/companyService";
import RouterProvider from "@pankod/refine-react-router-v6";
const { Title, Text } = Typography;
import { useNavigate } from "react-router-dom";
import { SendIcon } from "components/icons";
import { clearUnreadMsg, isDuplicate } from "utils/messageManager";
import { defaultConfig } from "config";
import dateFormat from "dateformat";
import authService from "services/authService";
import ScrollToBottom from 'react-scroll-to-bottom';
import { notifySuccess } from "components/Notification";
import PaymentPopup from "components/InitiatePayment";
import AttachmentPopup from "components/Attachment";

export const LeadShow: React.FC<IResourceComponentsProps> = () => {
    let page = 0;
    let pageSize = 300;
    const { open } = useNotification();
    const navigate = useNavigate();
    const { TextArea } = Input;
    const {SendOutlined, ArrowLeftOutlined,SettingOutlined,ReloadOutlined} = Icons
    const [message, setMessage] = useState('');
    const [companyName, setCompanyName] = useState("")
    const userCompanyId = RouterProvider.useParams().id;
    const myArray = userCompanyId.split("--")
    const userId = myArray[0];
    const companyId = myArray[1];
    const [messages, setMsgs] = useState<IMessage[]>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [companyInfo, setCompanInfo] = useState<IUserCompany>()
    const [showPayment, setShowPayment] = useState(false)
    
    //Attachement
    const [attachFile, setAttachFile] = useState(null)
    const [attachPopup, setAttachPopup] = useState(false)
    function handleChange(event:any) {
        setAttachFile(event.target.files[0])
        setAttachPopup(true)
    }
    
    function sendWebNotification(msg:string)
    {
        try
        {
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
          } else {
            console.log("This browser supports desktop notification");
            Notification.requestPermission();
             new Notification(msg);
          }
        }catch
        {}
    }

    let ws: WebSocket;
    const connect = () => {
        // console.log(ws,ws?.readyState)
        if (ws === undefined || (ws && ws.readyState === 3)) {

            ws = new WebSocket(defaultConfig.urls["ws"] + "?userId=" + authService.getLoggedInUserId());
            ws.onopen = function (e) {
            };
            ws.onmessage = function (payload) {
                if (payload.data){
                    handleOnMsgReceived(JSON.parse(payload.data));
                }
            };

            ws.onclose = function () {
            };
        }
    }

    useEffect(() => {
        let interval = setInterval(connect, 5000);
        async function getMsgs() {
            const [msgs, status] = await companyService.getMsgs(userId, companyId, page, pageSize);

            if (status == 200) {
                setCompanyName(msgs[0].companyName)
                setMsgs(msgs as IMessage[]);
                localStorage.setItem("chat" + userId + companyId, JSON.stringify(msgs));
            }
            else if(status==204){
                setCompanyName("")
                setMsgs([] as IMessage[]);
                localStorage.setItem("chat" + userId + companyId, JSON.stringify([]));
            }
            else {
                let msgs: IMessage[] = []
                setMsgs(msgs as IMessage[])
                localStorage.setItem("chat" + userId + companyId, JSON.stringify(msgs));
            }
            setLoading(false)
            // scrollToBottom();
        }
        getMsgs();
        const company = JSON.parse(localStorage.getItem("selected-company") || "")
        setCompanInfo(company)
        return () => {
            if (ws) ws.close()
            clearInterval(interval)
        };
    }, [userId,companyId])

    useEffect(()=>{
        scrollToBottom()
    },[messages])

    const handleOnMsgReceived = (msg: IMessage) => {
        if (!isDuplicate(messages, msg) && msg.userId == userId && msg.companyId == companyId) {
            //  console.log("duplicate nahi hai" +  messages?.length)
            let chats: IMessage[] = JSON.parse(localStorage.getItem("chat" + msg.userId + msg.companyId)!) as IMessage[];
            chats?.push(msg)
            if(msg && msg.fromto == "INCOMING")
            {
              //  console.log("called" + msg.fromto);
                sendWebNotification(msg.content);
            }
               

            let x = JSON.parse(JSON.stringify(chats))
            //  console.log("duplicate nahi hai-" +  messages?.length)
            setMsgs(x);
            localStorage.setItem("chat" + msg.userId + msg.companyId, JSON.stringify(chats));
        }else if(isDuplicate(messages, msg) && msg.userId == userId && msg.companyId == companyId && msg.messageType==="ORDER_CREATED"){
            //@ts-ignore
            let tempmsgs= [...messages]
            const index = tempmsgs.findIndex((sn:IMessage)=>sn.messageId===msg.messageId)
            if(index!=-1){
                tempmsgs[index] = {...msg}
                setMsgs(tempmsgs)
            }
        }
    }

    useEffect(() => {

    }, [JSON.stringify(messages)]);

    const save = async (attachment?:string,messageType?:string) => {

        
       const [messageSave, status] = await companyService.sendMsg(userId, companyId,attachment?attachment: message,messageType || "");
       if (status == 200) {
            messages?.push(messageSave)
            setMsgs(messages)
            setMessage("")
            scrollToBottom();
            setAttachFile(null)
            setAttachPopup(false)
        }
         else {
             open?.({ message: "Error Saving Information", type: "error" })
         }
    }

    const getOrderByOrderId =async (orderId:string,messageId:string)=>{
        const [res,status] = await companyService.updatedOrder(companyId,orderId)
        if(status===200){
            //@ts-ignore
            let tempmsgs= [...messages]
            const index = tempmsgs.findIndex((sn:IMessage)=>sn.messageId===messageId)
            if(index!=-1){
                tempmsgs[index].metadata.order = {...res}
                setMsgs(tempmsgs)
            }
        }
    }
    const renderItem = (item: IMessage) => {
        return (
            <div className="chatcontainer__innerdiv" >
                {
                    item.messageType && item.messageType.includes("ORDER")? <div className={item.fromto == "INCOMING" ? 'message left paymentWrp' : 'message right paymentWrp'}>
                        <p style={{fontWeight:100}}>{item.metadata.order.service?.title}</p>
                        <div className="payamnt" ><img width="10px" src="/rupee.svg" />{item.metadata.order.amount/100}</div>
                        {item.metadata.order.status==="INPROGRESS" ?
                            <div className="payinitbtn" >
                                <p>Payment Requested</p>
                                <ReloadOutlined className="reloadicon" onClick={()=>getOrderByOrderId(item.metadata.order.orderId,item.messageId)} />
                            </div> :
                            <div className="paysuccessbtn" >
                                <p>Payment Recieved</p>
                            </div>
                        }
                    </div>:
                    item.messageType && item.messageType.includes("ATTACHMENT")?
                    <li className={item.fromto == "INCOMING" ? 'message left' : 'message right'}>
                        <p className="messageWrp" >
                            <img src={item.content} style={{maxWidth:"300px",borderRadius:"17px", background:"white"}} />
                        </p>
                    {item.createdAt &&<h4>{dateFormat(new Date(item.createdAt! * 1000), "dd-mmm hh:MM TT")}</h4>}
                    </li>
                    :
                    <li className={item.fromto == "INCOMING" ? 'message left' : 'message right'}>
                    <p className="messageWrp" dangerouslySetInnerHTML={{__html:item.content.replace(/\n/g, "<br />")}} ></p>
                    {item.createdAt &&<h4>{dateFormat(new Date(item.createdAt! * 1000), "dd-mmm hh:MM TT")}</h4>}
                    </li>
                }
            </div>
            
        );
    };

    let messagesEnd: HTMLDivElement | null;
    const scrollToBottom = () => {
        messagesEnd?.scrollIntoView({ behavior: "smooth" });
    }

    const changeChatStatus =async (val:string)=>{
        // console.log(val)
        const [response,status] = await companyService.changeChatStatus(userId,companyId, val)
        if(status==204 || status==200) {
            notifySuccess("Status updated!")
            const defCompany = {...companyInfo}
            defCompany.status = val
            localStorage.setItem("selected-company", JSON.stringify(defCompany))
            window.location.reload()
        }
    }
    
    return (
        <div className="chats-wrapper" >
            {/* <audio src="/whatsapp.mp3" ref={audioRef} /> */}
            {attachFile && <AttachmentPopup sendImage={save} showPayment={attachPopup} setShowPayment={setAttachPopup} companyId={companyId} userId={userId} attachmentInfo={attachFile}  />}
            <PaymentPopup showPayment={showPayment} setShowPayment={setShowPayment}  companyId={companyId} userId={userId} />
            <div className="chatheader">
                <ArrowLeftOutlined className="chatheader__backicon" onClick={()=> navigate("/inbox")} />
                <div style={{flexGrow:'1'}}>
                    <h1>{userId} </h1><span>{companyName}</span>
                </div>
                <div style={{width:'200px'}} >
                    <Select
                        style={{width:'100%'}}
                        onChange={(val)=>changeChatStatus(val)}
                        defaultValue={companyInfo?.status}
                        value={companyInfo?.status}
                        options={[
                            {
                                label: "Change Chat Status",
                                value: "" || null,
                            },
                            {
                                label: "PENDING",
                                value: "PENDING",
                            },
                            {
                                label: "ASSIGNED",
                                value: "ASSIGNED",
                            },
                            {
                                label: "IN PROGRESS",
                                value: "IN_PROGRESS",
                            },
                            {
                                label: "SNOOZE",
                                value: "SNOOZE",
                            },
                            {
                                label: "RESOLVED",
                                value: "RESOLVED",
                            },
                            {
                                label: "ARCHIVED",
                                value: "ARCHIVED",
                            },

                        ]}
                    />
                </div>
            </div>
            <div className="chatcontainer">
                {messages?.map((item)=>renderItem(item))}
                <div ref={(el) => { messagesEnd = el; }}></div>
            </div>
           
            <div className="sendchat">
                {
                    !authService.isMarketPlaceAdmin() && <Button title="Initiate Payment" className="sendchat__initiatepay" onClick={()=>setShowPayment(!showPayment)} >
                        <img width="12px" src="/rupee.svg" />
                    </Button>
                }
                    <label htmlFor="file-input" className="sendchat__attachment" >
                            <img width="20px" src="/attach.png" />
                    </label>
                    <input id="file-input" type="file" onChange={handleChange} style={{visibility:"hidden", width:0}} />
                <TextArea className="sendchat__text" value={message} placeholder="Drop Message" onKeyDown={(e)=>{
                            // if(e.key === 'Enter'){
                            //     e.preventDefault();
                            //     save()
                            // }
                        }} onChange={evt => setMessage(evt.target.value)} />
                <br />
                <Button title="Send Message" className="sendchat__btn" onClick={()=>save()} >
                    <img width="17px" src="/sendbtn.svg" />
                </Button>
            </div>


        </div>
    );
}
