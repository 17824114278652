import { useEffect, useState,useRef } from "react";
//@ts-ignore
import Switch from "react-switch"
import {
    Button,
    Select
} from "@pankod/refine-antd";

interface FiltersPopupProps{
    filters:any;
    handleFilters:any;
    handleResponseFilters:any
    getStatusChats:any;
    showFilterspopup:boolean;
}

const FiltersPopup = (props:FiltersPopupProps)=>{
    const {filters,handleFilters,handleResponseFilters,getStatusChats,showFilterspopup} = props
    return (
        <div className="filterspopupWrp" style={{display:showFilterspopup ? "block":"none"}} >
            <div className="seperate-filter-wrp" >
                <h4>Based on status</h4>
                <Select
                    style={{width:'100%'}}
                    value={filters.chatStatus}
                    onChange={(val)=>{
                        // setChatType(val)
                        handleFilters("chatStatus", val)
                        // getStatusChats(val)
                    }}
                    defaultValue="ALL"
                    options={[
                        {
                            label: "All",
                            value: "ALL",
                        },
                        {
                            label: "PENDING",
                            value: "PENDING",
                        },
                        {
                            label: "ASSIGNED",
                            value: "ASSIGNED",
                        },
                        {
                            label: "IN PROGRESS",
                            value: "IN_PROGRESS",
                        },
                        {
                            label: "SNOOZE",
                            value: "SNOOZE",
                        },
                        {
                            label: "RESOLVED",
                            value: "RESOLVED",
                        },
                        {
                            label: "ARCHIVED",
                            value: "ARCHIVED",
                        },

                    ]}
                /> 
            </div>
            <div className="seperate-filter-wrp">
                <h4>Based on response</h4>
                <div className="res-filter-wrp" >
                    <div className={`filters-chip ${filters.response.includes("pendingResponse") ? "checked":""} `} onClick={()=>handleResponseFilters("pendingResponse")} >
                        <p>Pending Response</p>
                    </div>
                    <div className={`filters-chip ${filters.response.includes("isFirstResponseTatBreached") ? "checked":""} `} onClick={()=>handleResponseFilters("isFirstResponseTatBreached")}>
                        <p>First Response Tat Breached</p>
                    </div>
                </div>
            </div>
            <div className="seperate-filter-wrp" >
                <h4>Callback Requested</h4>
                <Switch onColor="#fec627" handleDiameter={10} width={50} height={20} checked={filters.callbackRequest} onChange={(chckd:boolean)=>{
                    // setCallbackRequest(chckd)
                    handleFilters("callbackRequest",chckd)
                    // getStatusChats(chatType,chckd)
                    }} />
            </div>
            <Button className="filtersApplybtn" onClick={getStatusChats} >Apply</Button>
        </div>
    )
}

export default FiltersPopup