import { useRef } from "react";
//@ts-ignore
import { Button } from "@pankod/refine-antd";

interface Props {
  show: boolean;
  getComment: (comment: string) => void;
  hidePopup: () => void;
  comment: string;
}

const AddCommentPopup = (props: Props) => {
  var { show, getComment, hidePopup, comment } = props;

  function handleCancel(event: any) {
    hidePopup();
  }
  async function handleCallback(event: any) {
    getComment(commentRef.current?.value!);
    hidePopup();
  }
  const commentRef = useRef<HTMLTextAreaElement>(null);
  return (
    <div
      className="filterspopupWrp"
      style={{ display: show ? "block" : "none", zIndex: 1001, top: "50%" }}
    >
      <div className="filterspopupHeader">
        <textarea
          className="commentBox"
          placeholder="Enter your comment here"
          id="input"
          defaultValue={comment}
          ref={commentRef}
        />
      </div>
      <Button className="filtersApplybtn" onClick={handleCallback}>
        Submit
      </Button>{" "}
      <Button className="filtersApplybtn" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  );
};

export default AddCommentPopup;
