import { useEffect, useState, useRef } from "react";
//@ts-ignore
import Switch from "react-switch"
import {
    Button,
    Select
} from "@pankod/refine-antd";
import { Product } from "interfaces";
import productService from "services/productService";
import { notifySuccess } from "./Notification";

interface ProductProps {
    product: Product | null;
    callback: any;
    show: boolean;
}

const ProductEditPopup = (props: ProductProps) => {
    const { product, callback, show } = props
    const [attributes, setAttributes] = useState<string>();
    const [carAttributes, setCarAttributes] = useState<string>();
    const [isAutomatic, setAutomatic] =  useState<boolean>(false);
    const [showin, setShow] = useState<boolean>(false);
    const  [initPriceValue, setInitPriceValue] = useState<number>();
    const [finalPrice, setFinalPrice] = useState<number>();

    useEffect(() => {
        setAutomatic(false)
        setFinalPrice(product?.finalPrice);
        setInitPriceValue(product?.finalPrice);
        setShow(show);
        setAttributes(JSON.stringify(product?.attributes))
        setCarAttributes(JSON.stringify(product?.carAttributes));
        if(product?.attributes?.["Transmission"])
        {        
            if(product?.attributes!["Transmission"].toUpperCase() ==="AUTOMATIC")
            {
                setAutomatic(true)
            }
        }
        if(product?.title?.toUpperCase().includes("AUTOMATIC") || product?.description?.toUpperCase().includes("AUTOMATIC"))
        {
            setAutomatic(true)
        }
        else if(product?.title?.toUpperCase().includes("MT") || product?.description?.toUpperCase().includes("MT") ||
        product?.title?.toUpperCase().includes("MANUAL") || product?.description?.toUpperCase().includes("MANUAL")
        )
        {
            setAutomatic(false)
        }
    }, []);

    useEffect(() => {
      console.log("called")
        setAutomatic(false)
        setFinalPrice(product?.finalPrice);
        setInitPriceValue(product?.finalPrice);
        setShow(show);
        setAttributes(JSON.stringify(product?.attributes));
        setCarAttributes(JSON.stringify(product?.carAttributes));
        if(product?.attributes?.["Transmission"])
        {        
            console.log("called1")
            if(product?.attributes!["Transmission"].toUpperCase() ==="AUTOMATIC")
              setAutomatic(true)
        }
        if(product?.title?.toUpperCase().includes("AUTOMATIC") || product?.description?.toUpperCase().includes("AUTOMATIC"))
        {
            console.log("called2")
            setAutomatic(true)
        }
        else if(product?.title?.toUpperCase().includes("MT") || product?.description?.toUpperCase().includes("MT") ||
        product?.title?.toUpperCase().includes("MANUAL") || product?.description?.toUpperCase().includes("MANUAL")
        )
        { console.log("called3")
            setAutomatic(false)
        }
    }, [show]);

    function handleAttributeChange(event: any) {

        setAttributes(event.target.value);
    }
    function handlePriceChange(event: any) {

        setFinalPrice(event.target.value);
    }

    

    const updateProduct = async () => {
        if(attributes)
        {
            product!.attributes = JSON.parse(attributes);
           
            product!.attributes!["Transmission"]  = isAutomatic? "Automatic":"Manual";
     
          
        }
        if(finalPrice)
            product!.finalPrice = finalPrice

        const [productRes, status] = await productService.saveProduct(product!, product!.finalPrice! !== initPriceValue);
        if(status ==200)
        {
            notifySuccess("Product updated successfully");
           // console.log("updated prodcut" +  JSON.stringify(productRes))
        }

    };

    function handleCancel(event: any) {
        callback()
        setShow(false);
    }
    async function handleCallback(event: any) {

        if (callback) {
           await updateProduct()
            callback()
            setShow(false);
        }

    }
    return (
        <div className="filterspopupWrp" style={{ display: showin ? "block" : "none" }} >
            <div className="seperate-filter-wrp" >
                <h4>Final Price</h4>
                <textarea value={finalPrice} style={{ width: '100%' }} id="input" onChange={handlePriceChange} />
            </div>
            <div className="seperate-filter-wrp" >
                <h4>Is Automatic</h4>
                <Switch onColor="#fec627" handleDiameter={10} width={50} height={20} checked={isAutomatic} onChange={(chckd:boolean)=>{
                  
                    setAutomatic(chckd)
                    // getStatusChats(chatType,chckd)
                    }} />
            </div>
            <div className="seperate-filter-wrp">
                <h4>Attributes</h4>
                <div className="res-filter-wrp" >
                    <textarea rows={5} value={attributes} style={{ width: '100%'  }} id="input" onChange={handleAttributeChange} />
                </div>
            </div>
            <div className="seperate-filter-wrp">
                <h4>Converted Attributes</h4>
                <div className="res-filter-wrp" >
                    <textarea rows={5} value={carAttributes} style={{ width: '100%'  }} id="input" disabled />
                </div>
            </div>
          

            <Button className="filtersApplybtn" onClick={handleCallback} >Submit</Button> <Button className="filtersApplybtn" onClick={handleCancel} >Cancel</Button>
        </div>
    )
}

export default ProductEditPopup