export const  getCurrentDateTime =() =>{
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();

    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours === 0 ? 12 : hours;

    const formattedDateTime = `${day}-${month}-${year}  ${hours}:${minutes} ${ampm}`;
    return formattedDateTime;
  }
export default {
    getCurrentDateTime  
}