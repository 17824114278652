import { useEffect, useState,useRef } from "react";
//@ts-ignore
import Switch from "react-switch"
import {
    Button,
    Icons,
    Select
} from "@pankod/refine-antd";
import companyService from "services/companyService";
import { v4 as uuidv4 } from 'uuid';
import { notifySuccess } from "./Notification";

const PaymentPopup = ({userId,showPayment,setShowPayment,companyId}:{showPayment:boolean,setShowPayment:any,companyId:string,userId:string})=>{
    const {CloseOutlined} = Icons
    const [initpaymentData, setInitpaymentData] = useState({
        "amountInLowestDen":0,
        "currency":"INR",
        "orderType":"SERVICE",
        "serviceDescription":""
    })

    const handleChange = (e:any)=>{
        setInitpaymentData((prev)=>{
            return {
                ...prev,
                [e.target.name]:e.target.value
            }
        })
    }

    const initpaymentSubmit =async ()=>{
        let referenceId:string=localStorage.getItem("paymentreferenceId") || ""
        if(!referenceId){
            referenceId = uuidv4()
            localStorage.setItem("paymentreferenceId",referenceId || "")
        }
        const [res,status] = await companyService.initialisePayment({...initpaymentData,amountInLowestDen:(initpaymentData.amountInLowestDen * 100),referenceId,companyId,userId})
       // console.log(res)
        if(status==200){
            notifySuccess("Payment Initiated")
            localStorage.removeItem("paymentreferenceId")
            setShowPayment(false)
        }
        else
        {
            notifySuccess("Error Initiating. Try again")
            localStorage.removeItem("paymentreferenceId")
            setShowPayment(false)
        }
    }

    return (
        <div className="initiatepaymentpopupWrp" style={{display:showPayment ? "block":"none"}} >
            <div className="closeicon" >
                <CloseOutlined onClick={()=>setShowPayment(false)} />
            </div>
            <textarea placeholder="Enter payment description" name="serviceDescription" className="init_payment_desc"
             value={initpaymentData.serviceDescription} onChange={handleChange} />
             {/* <div>
                <img width="10px" src="/rupee.svg" /> */}
                <input placeholder="Enter amount" type="number" name="amountInLowestDen" className="init_payment_input"
                value={initpaymentData.amountInLowestDen} onChange={handleChange} />
             {/* </div> */}
            <Button onClick={()=>initpaymentSubmit()} className="init_payment_btn" >Request for Payment</Button>
        </div>
    )
}

export default PaymentPopup