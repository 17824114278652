import React, { useEffect, useState } from "react";
import { GetOneResponse, IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import {
    Button,
    Edit,
    Form,
    Input,
    Select,
    Typography,
    Upload,
    getValueFromEvent
} from "@pankod/refine-antd";
import {
    useApiUrl
} from "@pankod/refine-core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICategory, ICompany, ISubCategory } from "interfaces";
import companyService from "services/companyService";
import { useNotification } from "@pankod/refine-core";
import { notifyError } from "components/Notification";
//@ts-ignore
import ImageUploader from 'react-image-upload';
import "react-image-upload/dist/index.css";
import { RMIUploader } from "react-multiple-image-uploader";
//@ts-ignore
import Switch from "react-switch"
import RouterProvider from "@pankod/refine-react-router-v6";
const { Title, Text } = Typography;

export const CompanyInfoEdit: React.FC<IResourceComponentsProps> = () => {
    const phregex = /^[6-9]{1}[0-9]{9}$/;
    const [form] = Form.useForm();
    const { open } = useNotification();
    const companyId = RouterProvider.useParams().id;
    const navigate = useNavigate();
    const [company, setCompany] = useState<ICompany>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [categories, setCategories] = useState<any[]>([])
    const [subcategories, setSubCategories] = useState<any[]>([])
    const [isChatEnabled, setChatEnabled] = useState(false)
    const [isCallEnabled, setIsCallEnabled] = useState(false)
    const [isDirectCallEnabled, setIsDirectCallEnabled] = useState(false)
    const [paidService, setIsPaidService] = useState(false)
    const [isClaimed, setIsClaimed] = useState(false)
    const [logo, setLogo] = useState<any>()
    const [mainImage, setMainImage] = useState<any>()
    const [customerCareNumbers, setCustomerCareNumbers] = useState<any>([])
    const [ccaName, setCcaName] = useState("")
    const [ccaValue, setCcaValue] = useState("")

    const [catDescps, setCatDescps] = useState<any>([])
    const [catdescpName, setCatDescpName] = useState("")
    const [catdescpValue, setCatdescpValue] = useState("")
    const [upiAccount, setUpiAccount] = useState("")
    const [allUpiAccnts, setAllUpiAccnts] = useState<string[]>([])

    const [youtubeVideo, setYoutubeVideo] = useState("")
    const [allYoutubeVideos, setAllYoutubeVideos] = useState<string[]>([])
    
    const [socialMediaAcc, setSocialMediaAcc] = useState<any>([])
    const [socialMediaName, setSocialMediaName] = useState("")
    const [socialMediaValue, setSocialMediaValue] = useState("")

    const [allImages, setAllImages] = useState<string[]>([])

    const [customService, setCustomService] = useState({
        title: "",
        description: "",
        serviceCost: "",
        subCategory: "",
        icon: ""
    })

    const [allServices, setAllService] = useState<any>([])

    const addCustomerCareNumbers = (type: String) => {
        if (type === "SOCIALMEDIA") {
            const newcca = { socialMediaName, socialMediaValue }
            setSocialMediaAcc([...socialMediaAcc, newcca])
            setSocialMediaName("")
            setSocialMediaValue("")
        }
        if (type === "CCANUMBER") {
            const newcca = { ccaName, ccaValue }
            setCustomerCareNumbers([...customerCareNumbers, newcca])
            setCcaName("")
            setCcaValue("")
        }
        else if (type === "CATDESCP") {
            const newcca = { catdescpName, catdescpValue }
            setCatDescps([...catDescps, newcca])
            setCatDescpName("")
            setCatdescpValue("")
        }
        else if (type === "COMPSERVICE") {
            setAllService([...allServices, customService])
            setCustomService({
                title: "",
                description: "",
                serviceCost: "",
                subCategory: "",
                icon: ""
            })
        }else if(type==="UPI"){
            setAllUpiAccnts([...allUpiAccnts,upiAccount])
            setUpiAccount("")
        }
        else if(type==="YOUTUBE"){
            setAllYoutubeVideos([...allYoutubeVideos,youtubeVideo])
            setYoutubeVideo("")
        }
    }
    const deleteCCANumber = (index: number, type: string) => {
        if (type === "SOCIALMEDIA") {
            const filtered = socialMediaAcc.filter((sn: any, i: number) => i != index)
            setSocialMediaAcc(filtered)
        }
        if (type === "CCANUMBER") {
            const filtered = customerCareNumbers.filter((sn: any, i: number) => i != index)
            setCustomerCareNumbers(filtered)
        }
        else if (type === "CATDESCP") {
            const filtered = catDescps.filter((sn: any, i: number) => i != index)
            setCatDescps(filtered)
        }
        else if (type === "COMPSERVICE") {
            const filtered = allServices.filter((sn: any, i: number) => i != index)
            setAllService(filtered)
        }
        else if(type==="UPI"){
            const filtered = allUpiAccnts.filter((sn: any, i: number) => i != index)
            setAllUpiAccnts(filtered)
        }
        else if(type==="YOUTUBE"){
            const filtered = allYoutubeVideos.filter((sn: any, i: number) => i != index)
            setAllYoutubeVideos(filtered)
        }
    }

    const apiUrl = useApiUrl();
    useEffect(() => {
        async function getcompanyInfo() {
            const [company, status] = await companyService.getCompanyInfo(companyId);
            if (status == 200) {
              
                setCompany(company as ICompany);
                setChatEnabled(company.chatEnabled)
                setIsCallEnabled(company.callEnabled)
                setIsDirectCallEnabled(company.directCallEnabled)
                const custServic = company.companyServices ? company.companyServices.map((sn:any)=>{
                    return {
                        title:sn.title,
                        description:sn.description,
                        serviceCost:sn.serviceCost,
                        subCategory:{value:sn.subCategory},
                        icon:""
                    }
                }) :[]
                setAllService(custServic)
                setAllUpiAccnts(company.upiAccounts || [])
                setAllYoutubeVideos(company.youtubeVideos || [])
                // setAllService(company.companyServices)
                setIsClaimed(company.claimed)
                setAllImages(company.images)
                let tempcatdescp = []
                for (let key in company.catDescriptions) {
                    tempcatdescp.push({ catdescpName: {value:key}, catdescpValue: company.catDescriptions[key] });
                }
                setCatDescps(tempcatdescp)
                let tempccanumbers = []
                for (let key in company.customerCareNumbersMap) {
                    tempccanumbers.push({ ccaName: key, ccaValue: company.customerCareNumbersMap[key] });
                }
                setCustomerCareNumbers(tempccanumbers)
                let tempsocialmedia = []
                for (let key in company.socialMediaUrls) {
                    tempsocialmedia.push({ socialMediaName: key, socialMediaValue: company.socialMediaUrls[key] });
                }
                setSocialMediaAcc(tempsocialmedia)

                const [categoriesres, statuscat] = await companyService.getCategories()
                if (company.category){
                    const catId = categoriesres.filter((sn:any)=>sn.title === company.category)[0];
                    if(catId)
                        await getSubCategories(catId.categoryId)
                }
                if (statuscat == 200) {
                    let cts = []
                    cts.push({ label: "Select your category", value: "" })
                    categoriesres.map((sn: ICategory) => {
                        cts.push({
                            value: sn.title,
                            label: sn.title
                        })
                    })
                    setCategories(cts)
                }
            }
            setLoading(false)
        }
        getcompanyInfo();
    }, [])

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        form.resetFields();
    }, [company]);

    const save = async () => {
        let merged = { ...company, ...form.getFieldsValue() };
        merged.images = []
        try {
            if (logo && logo.file) {
                const [logourl, status] = await companyService.imageUploadService(logo.file)
                merged.logo = logourl

            }
            if (mainImage && mainImage.file) {
                const [mainImageurl, status] = await companyService.imageUploadService(mainImage.file)
                merged.mainImage = mainImageurl

            }
            if(dataSources){
                for(var i=0;i<dataSources.length;i++){
                    const [dtssrc, status] = await companyService.imageUploadService(dataSources[i])
                    merged.images.push(dtssrc)
                }
            }
        } catch (error) {
            notifyError("There was some problem while uploading image.")
        }
        merged.claimed = isClaimed
        merged.chatEnabled = isChatEnabled
        merged.callEnabled = isCallEnabled
        merged.directCallEnabled = isDirectCallEnabled
        merged.upiAccounts = [...allUpiAccnts]
        merged.youtubeVideos = [...allYoutubeVideos]
        // merged.catDescriptions =  new Map(catDescps.map((sn:any) => [sn.catdescpName.value, sn.catdescpValue]))
        // console.log(catDescps)
        merged.catDescriptions = catDescps.reduce((obj: any, sn: any) => (obj[sn.catdescpName.value] = sn.catdescpValue, obj), {})

        // merged.customerCareNumbersMap = new Map(customerCareNumbers.map((sn:any) => [sn.ccaName, sn.ccaValue]))
        merged.customerCareNumbersMap = customerCareNumbers.reduce((obj: any, sn: any) => (obj[sn.ccaName] = sn.ccaValue, obj), {})
        merged.socialMediaUrls = socialMediaAcc.reduce((obj: any, sn: any) => (obj[sn.socialMediaName] = sn.socialMediaValue, obj), {})

        merged.socialMediaAdIds = {
            "INSTAGRAM": merged.socialMediaAdIds_INSTAGRAM ? merged.socialMediaAdIds_INSTAGRAM : (company?.socialMediaAdIds ? company?.socialMediaAdIds["INSTAGRAM"] : ""),
            "YOUTUBE": merged.socialMediaAdIds_YOUTUBE ? merged.socialMediaAdIds_YOUTUBE : (company?.socialMediaAdIds ? company?.socialMediaAdIds["YOUTUBE"] : ""),
            "FACEBOOK": merged.socialMediaAdIds_FACEBOOK ? merged.socialMediaAdIds_FACEBOOK : (company?.socialMediaAdIds ? company?.socialMediaAdIds["FACEBOOK"] : ""),
        }
        if (allServices) {
            merged.companyServices = allServices.map((sn: any) => {
                return {
                    title: sn.title,
                    description: sn.description,
                    serviceCost: sn.serviceCost,
                    subCategory: sn.subCategory.value,
                    icon: ""
                }
            })
        }

        delete merged.catDescriptions_category
        delete merged.catDescriptions_value
        delete merged.customerCareNumbersMap_type
        delete merged.customerCareNumbersMap_value
        delete merged.socialMediaAdIds_INSTAGRAM
        delete merged.socialMediaAdIds_YOUTUBE
        delete merged.socialMediaAdIds_FACEBOOK
        // console.log(merged)
        const [companyUpdated, status] = await companyService.updateCompanyInfo(merged, companyId);
        if (status == 200) {
            open?.({ message: "successfully saved", type: "success" })
            navigate(-1);
        }
        else {
            open?.({ message: "Error Saving Information", type: "error" })
        }
    }

    const getSubCategories = async (catId?: string) => {
        let categoryId = catId || ""
        if (!catId) {
            setCompany({ ...(company as ICompany), ...(form.getFieldsValue() as ICompany), subCategories: [] });
            setCatDescps([])
            setCatDescpName("")
            setCatdescpValue("")
            categoryId = categories.filter((sn:any)=>sn.label === form.getFieldValue("category"))[0].value
        }
        const [subcategories, status] = await companyService.getSubCategories(categoryId)
        if (status == 200) {
            let cts = []
            cts.push({ label: "Select your sub-category", value: "" })
            subcategories.map((sn: ISubCategory) => {
                cts.push({
                    value: sn.title,
                    label: sn.title
                })
            })
            setSubCategories(cts)
        }
    }

    function getImageFileObject(imageFile: any, imageType: string) {
        if (imageType === "logo")
            setLogo(imageFile)
        if (imageType === "mainimage") {
            setMainImage(imageFile)
        }
    }
    function runAfterImageDelete(file: any, imageType: string) {
        if (imageType === "logo")
            setLogo(undefined)
        if (imageType === "mainimage") {
            setMainImage(undefined)
        }
    }
    const [dataSources, setDataSources] = useState<any>()

    

    return (
        <Edit
            title="Company Information"
            isLoading={isLoading}
            recordItemId={company?.companyId}
            saveButtonProps={{ onClick: save }}
        >
            <ToastContainer />
            <div className="edit-company-logo" >
                <div>

                    <Form form={form} initialValues={{ ...company }} layout="vertical">

                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input value={company?.companyName} ></Input>

                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="companyStatus"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: "PUBLISHED",
                                        value: "PUBLISHED",
                                    },
                                    {
                                        label: "SUBMITTED",
                                        value: "SUBMITTED",
                                    },
                                    {
                                        label: "REJECTED",
                                        value: "REJECTED",
                                    },
                                ]}
                            />
                        </Form.Item>
                        {/* <Form.Item
                        label="Logo"
                        name="logo"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item> */}
                        <Form.Item
                            label="Plan"
                            name="plan"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: "Startup",
                                        value: "A",
                                    },
                                    {
                                        label: "Enterprise",
                                        value: "B",
                                    },
                                    {
                                        label: "Registered Dealer",
                                        value: "C",
                                    }

                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Phone Number"
                            name="phoneNumber"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Contact Person Name"
                            name="contactPersonName"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Contact Person Number"
                            name="contactPersonNumber"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Website"
                            name="domain"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Listing Weight"
                            name="cIndex"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="firstMsg reminder number"
                            name="firstMsgSMSNumber"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Support Email"
                            name="supportEmail"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="AddressInfo.address"
                            name={['addressInfo', 'address']}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="AddressInfo.city"
                            name={['addressInfo', 'city']}
                       
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="AddressInfo.Lat"
                            name={['addressInfo', 'latLong', 'lat']}
                
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="AddressInfo.Lng"
                            name={['addressInfo', 'latLong', 'lng']}
                        
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="ProductSource"
                            name="productSource"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="DealerId(incase of cars)"
                            name="externalReferenceId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Dealer Path(incase of cars)"
                            name="externalReferencePath"
                           
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input  value={`https://www.allusedcars.in/dealers/${company?.externalReferencePath}`} />
                        </Form.Item>


                        
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Service Cost"
                            name="serviceCostText"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Whatsapp CatalogueId"
                            name="catalogueId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='UPI accounts'
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        ></Form.Item>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-3rem' }}>
                            <Form.Item
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input value={upiAccount} onChange={(e) => setUpiAccount(e.target.value)} />
                            </Form.Item>
                            <div>
                                <Button onClick={() => addCustomerCareNumbers("UPI")}>Add</Button>
                            </div>
                        </div>
                        {
                            allUpiAccnts && allUpiAccnts.length > 0 && allUpiAccnts.map((sn: any, i: number) => (
                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                    <Form.Item style={{ marginRight: '3rem' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input disabled value={sn} />
                                    </Form.Item>
                                    <div>
                                        <Button onClick={() => deleteCCANumber(i, "UPI")} >Delete</Button>
                                    </div>
                                </div>
                            ))
                        }

                        <Form.Item
                            label='Youtube Videos'
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        ></Form.Item>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-3rem' }}>
                            <Form.Item
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input value={youtubeVideo} onChange={(e) => setYoutubeVideo(e.target.value)} />
                            </Form.Item>
                            <div>
                                <Button onClick={() => addCustomerCareNumbers("YOUTUBE")}>Add</Button>
                            </div>
                        </div>
                        {
                            allYoutubeVideos && allYoutubeVideos.length > 0 && allYoutubeVideos.map((sn: any, i: number) => (
                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                    <Form.Item style={{ marginRight: '3rem' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input disabled value={sn} />
                                    </Form.Item>
                                    <div>
                                        <Button onClick={() => deleteCCANumber(i, "YOUTUBE")} >Delete</Button>
                                    </div>
                                </div>
                            ))
                        }

                        {/* <Form.Item
                            label="Customer Care Numbers"
                            name="customerCareNumbers"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item> */}
                        <Form.Item
                            label="Chat Provider"
                            name="chatProvider"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Customer Care Numbers'
                            name="customerCareNumbersMap"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >

                        </Form.Item>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-3rem' }}>
                            <Form.Item style={{ marginRight: '3rem' }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input value={ccaName} placeholder="Type of number Eg. Credit/debit card etc." onChange={(e) => setCcaName(e.target.value)} />
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input value={ccaValue} onChange={(e) => setCcaValue(e.target.value)} />
                            </Form.Item>
                            <div>
                                <Button onClick={() => addCustomerCareNumbers("CCANUMBER")}>Add</Button>
                            </div>
                        </div>
                        {
                            customerCareNumbers && customerCareNumbers.length > 0 && customerCareNumbers.map((sn: any, i: number) => (
                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                    <Form.Item style={{ marginRight: '3rem' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input disabled value={sn.ccaName} />
                                    </Form.Item>

                                    <Form.Item
                                    >
                                        <Input disabled value={sn.ccaValue} />
                                    </Form.Item>
                                    <div>
                                        <Button onClick={() => deleteCCANumber(i, "CCANUMBER")} >Delete</Button>
                                    </div>
                                </div>
                            ))
                        }

                        <Form.Item
                            label='Link Social-Media Accounts'
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >

                        </Form.Item>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-3rem' }}>
                            <Form.Item style={{ marginRight: '3rem' }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input value={socialMediaName} placeholder="Social media" onChange={(e) => setSocialMediaName(e.target.value)} />
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input value={socialMediaValue} onChange={(e) => setSocialMediaValue(e.target.value)} />
                            </Form.Item>
                            <div>
                                <Button onClick={() => addCustomerCareNumbers("SOCIALMEDIA")}>Add</Button>
                            </div>
                        </div>
                        {
                            socialMediaAcc && socialMediaAcc.length > 0 && socialMediaAcc.map((sn: any, i: number) => (
                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                    <Form.Item style={{ marginRight: '3rem' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input disabled value={sn.socialMediaName} />
                                    </Form.Item>

                                    <Form.Item
                                    >
                                        <Input disabled value={sn.socialMediaValue} />
                                    </Form.Item>
                                    <div>
                                        <Button onClick={() => deleteCCANumber(i, "SOCIALMEDIA")} >Delete</Button>
                                    </div>
                                </div>
                            ))
                        }

                        <Form.Item
                            label='Social Media Ads Info'
                            // name="socialmedia"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                        </Form.Item>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-3rem' }}>
                            <Form.Item

                                name="socialMediaAdIds_YOUTUBE"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Enter youtube ad Id." defaultValue={company?.socialMediaAdIds ? company?.socialMediaAdIds["YOUTUBE"] : ""}  />
                            </Form.Item>
                            <Form.Item

                                name="socialMediaAdIds_FACEBOOK"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Enter facebook ad Id." defaultValue={company?.socialMediaAdIds ? company?.socialMediaAdIds["FACEBOOK"] : ""} />
                            </Form.Item>
                            <Form.Item

                                name="socialMediaAdIds_INSTAGRAM"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Enter instagram ad Id." defaultValue={company?.socialMediaAdIds ? company?.socialMediaAdIds["INSTAGRAM"] : ""} />
                            </Form.Item>
                        </div>

                        {localStorage.getItem("roles")?.includes("ADMIN") &&
                            <div>
                                <Form.Item
                                    label="Chat Enabled"
                                    name="chatEnabled"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Switch handleDiameter={10} width={50} height={20} checked={isChatEnabled} onChange={(chckd: boolean) => setChatEnabled(chckd)} />
                                </Form.Item>
                                <Form.Item
                                    label="Call Enabled"
                                    name="callEnabled"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Switch handleDiameter={10} width={50} height={20} checked={isCallEnabled} onChange={(chckd: boolean) => setIsCallEnabled(chckd)} />
                                </Form.Item>
                                <Form.Item
                                    label="Direct Call Enabled"
                                    name="directCallEnabled"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Switch handleDiameter={10} width={50} height={20} checked={isDirectCallEnabled} onChange={(chckd: boolean) => setIsDirectCallEnabled(chckd)} />
                                </Form.Item>

                                <Form.Item
                                    label="Paid Service"
                                    name="paidService"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Switch handleDiameter={10} width={50} height={20} checked={paidService} onChange={(chckd: boolean) => setIsPaidService(chckd)} />
                                </Form.Item>
                                <Form.Item
                                    label="Claimed"
                                    name="claimed"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Switch handleDiameter={10} width={50} height={20} checked={isClaimed} onChange={(chckd: boolean) => setIsClaimed(chckd)} />
                                </Form.Item>
                            </div>
                        }
                        <Form.Item
                            label="Category"
                            name="category"
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                        >
                            <Select
                                defaultValue={""}
                                onChange={() => getSubCategories(undefined)}
                                options={categories}
                            />
                        </Form.Item>


                        <Form.Item
                            label="Sub-Category"
                            name="subCategories"
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                        >
                            <Select
                                options={subcategories}
                                aria-multiselectable={true}
                                mode="multiple"
                            />
                        </Form.Item>

                        <Form.Item
                            label='Category Description'
                            name="catDescriptions"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >

                        </Form.Item>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-3rem' }}>
                            <Form.Item style={{ marginRight: '3rem' }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '10rem' }}
                                    options={subcategories}
                                    value={catdescpName}
                                    onChange={(value, option) => setCatDescpName(option)}
                                />
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input value={catdescpValue} onChange={(e) => setCatdescpValue(e.target.value)} />
                            </Form.Item>
                            <div>
                                <Button onClick={() => addCustomerCareNumbers("CATDESCP")}>Add</Button>
                            </div>
                        </div>
                        {
                            catDescps && catDescps.length > 0 && catDescps.map((sn: any, i: number) => (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Form.Item style={{ marginRight: '3rem' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '10rem' }}
                                            options={subcategories}
                                            disabled
                                            value={sn.catdescpName}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input disabled value={sn.catdescpValue} />
                                    </Form.Item>
                                    <div>
                                        <Button onClick={() => deleteCCANumber(i, "CATDESCP")}>Delete</Button>
                                    </div>
                                </div>
                            ))
                        }

                        <Form.Item
                            label='Company Service'
                            // name="socialmedia"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                        </Form.Item>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-3rem' }}>
                            <table>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Sub-Category</th>
                                    <th>Service Cost</th>
                                </tr>
                                <tr>
                                    <td>
                                        <Input value={customService.title} onChange={(e) => {
                                            setCustomService((prev) => {
                                                return {
                                                    ...prev,
                                                    title: e.target.value
                                                }
                                            })
                                        }} />
                                    </td>
                                    <td>
                                        <Input value={customService.description} onChange={(e) => {
                                            setCustomService((prev) => {
                                                return {
                                                    ...prev,
                                                    description: e.target.value
                                                }
                                            })
                                        }} />
                                    </td>
                                    <td>
                                        <Select
                                            style={{ width: '10rem' }}
                                            options={subcategories}
                                            value={customService.subCategory}
                                            onChange={(value, option) => {
                                                setCustomService((prev) => {
                                                    return {
                                                        ...prev,
                                                        subCategory: option
                                                    }
                                                })
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Input value={customService.serviceCost} onChange={(e) => {
                                            setCustomService((prev) => {
                                                return {
                                                    ...prev,
                                                    serviceCost: e.target.value
                                                }
                                            })
                                        }} />
                                    </td>
                                    <td>
                                        <Button onClick={() => addCustomerCareNumbers("COMPSERVICE")} >Add</Button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {
                            allServices && allServices.length > 0 && allServices.map((sn: any, i: number) => (
                                <tr>
                                    <td>
                                        <Input value={sn.title} disabled />
                                    </td>
                                    <td>
                                        <Input value={sn.description} disabled />
                                    </td>
                                    <td>
                                        <Select
                                            style={{ width: '10rem' }}
                                            options={subcategories}
                                            value={sn.subCategory}
                                            disabled />
                                    </td>
                                    <td>
                                        <Input value={sn.serviceCost} disabled />
                                    </td>
                                    <td>
                                        <Button onClick={() => deleteCCANumber(i, "COMPSERVICE")} >Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </Form>
                </div>
                <div style={{ padding: '20px' }}>
                    <Title level={5}>Upload Logo</Title>
                    <ImageUploader
                        style={{ height: 200, width: 200, background: "lightgray", border: "1px solid" }}
                        uploadIcon={
                            <img width={"100%"} src={company?.logo || "/uploadimage.png"} />
                        }
                        onFileAdded={(img: any) => getImageFileObject(img, "logo")} // function that runs to confirm that your image actually exists
                        onFileRemoved={(img: any) => runAfterImageDelete(img, "logo")} // function runs on once you delete your image
                    />

                    <Title level={5}>Main Image</Title>
                    <ImageUploader
                        style={{ height: 200, width: 200, background: "lightgray", border: "1px solid" }}
                        uploadIcon={
                            <img width={"100%"} src={company?.mainImage || "/uploadimage.png"} />
                        }
                        onFileAdded={(img: any) => getImageFileObject(img, "mainimage")} // function that runs to confirm that your image actually exists
                        onFileRemoved={(img: any) => runAfterImageDelete(img, "mainimage")} // function runs on once you delete your image
                    />
                    <Title level={5}>Multiple Images</Title>
                    <Form.Item >
                        <Form.Item
                            name="multipleimages"
                            valuePropName="fileList"
                            // getValueFromEvent={getValueFromEvent}
                            noStyle
                        >
                            <input type="file" multiple onChange={(e)=>setDataSources(e.target.files)} />
                        </Form.Item>
                    </Form.Item>
                    <div className="multiimages" >
                        {
                            allImages && allImages.map((singimg:string)=>(
                                <div>
                                    <img src={singimg} width={"200px"} />
                                </div>
                            ))
                        }
                    </div>
                </div>


            </div>

        </Edit>
    );
};
