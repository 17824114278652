import {
  AuthPage,
  ErrorComponent,
  Icons,
  Layout,
  Sider,
  notificationProvider,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import {
  AuthProvider,
  Authenticated,
  CanAccess,
  Refine,
} from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { BasicInfoIcon, CRMIcon, ChatIcon } from "components/icons";
import { defaultConfig } from "config";
import { Bookings } from "pages/bookings";
import { CompanyInfoCRM, CompanyInfoCRMEdit } from "pages/company-info-crm";
import { InboxList, InboxShow } from "pages/inbox";
import { LeadList, LeadShow } from "pages/leads";
import { MyOrders } from "pages/myorders";
import { PaymentList } from "pages/payment";
import { PostCreate, PostEdit, PostList, PostShow } from "pages/posts";
import { PostsList } from "pages/posts_new/list";
import { ProductList, ProductShow } from "pages/products";
import axiosInstance from "services/axios";
import "styles/antd.less";
import { Settings } from "./components/icons/settings";
import { AssignAgent } from "./pages/assignagent";
import { CompanyInfo, CompanyInfoEdit } from "./pages/company-info";
import { Login } from "./pages/login";
import authService from "./services/authService";
import { parseJwt } from "./utils/parse-jwt";
const { Link } = routerProvider;
//const axiosInstance = axios.create();

const API_URL = "";

const AuthenticatedCompanyInfo = () => {
  return (
    <Authenticated>
      <CompanyInfo />
    </Authenticated>
  );
};

const AuthenticatedCompanyInfoCRM = () => {
  return (
    <Authenticated>
      <CompanyInfoCRM />
    </Authenticated>
  );
};

const AuthenticatedInboxList = () => {
  return (
    <Authenticated>
      <InboxList />
    </Authenticated>
  );
};

const AuthenticatedLeadList = () => {
  return (
    <Authenticated>
      <LeadList />
    </Authenticated>
  );
};

const AuthenticatedProductList = () => {
  return (
    <Authenticated>
      <ProductList />
    </Authenticated>
  );
};

const App: React.FC = () => {
  const authProvider: any = {
    login: async ({ credential }: CredentialResponse) => {
      const t = await axiosInstance.axiosInstance.get(
        defaultConfig.urls.serviceBaseUrl +
          defaultConfig.urls.loginVerifyUrl +
          credential
      );
      const profileObj = credential ? parseJwt(credential) : null;
      if (profileObj) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...profileObj,
            avatar: profileObj.picture,
          })
        );
      }
      const benamJWT = t.data.token ? parseJwt(t.data.token) : null;
      authService.setScope(benamJWT);
      //if user is not a valid user
      if (
        !authService.isSuperAdmin() &&
        !authService.isCompanyUser() &&
        !authService.isCompanyAdmin()
      ) {
        authService.clearAll();
        Promise.reject();
      } else {
        localStorage.setItem("token", `${t.data.token}`);

        return Promise.resolve();
      }
    },
    logout: () => {
      const token = localStorage.getItem("token");

      if (token && typeof window !== "undefined") {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        authService.clearAll();

        window.google?.accounts.id.revoke(token, () => {
          return Promise.resolve();
        });
      }

      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      const token = localStorage.getItem("token");

      if (token) {
        return Promise.resolve();
      }
      return Promise.reject();
    },

    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) {
        return Promise.resolve(JSON.parse(user));
      }
    },
  };
  const { UsergroupAddOutlined, DollarCircleOutlined, TagsOutlined } = Icons;
  return (
    <Refine
      dataProvider={dataProvider(API_URL, axiosInstance.axiosInstance)}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <AuthenticatedCompanyInfo />,
            path: "/settings/company-info",
            layout: true,
          },
          {
            element: <AuthenticatedInboxList />,
            path: "/inbox",
            layout: true,
          },
          {
            element: <AuthenticatedLeadList />,
            path: "/leads",
            layout: true,
          },
          {
            element: <AuthenticatedProductList />,
            path: "/products",
            layout: true,
          },
          {
            element: <AuthenticatedCompanyInfoCRM />,
            path: "/settings/company-info-crm",
            layout: true,
          },
        ] as typeof routerProvider.routes,
      }}
      authProvider={authProvider}
      resources={
        authService.isSuperAdmin()
          ? [
              {
                name: "Inbox",
                options: { route: "inbox", label: "Inbox" },
                list: InboxList,
                show: InboxList,
                icon: <ChatIcon />,
              },
              {
                name: "Posts",
                options: { route: "posts", label: "Posts" },
                list: PostsList,
                show: PostsList,
                icon: <ChatIcon />,
              },
              {
                name: "Leads",
                options: { route: "leads", label: "Leads" },
                list: LeadList,
                show: LeadShow,
                icon: <DollarCircleOutlined />,
              },
              {
                name: "Products",
                options: { route: "products", label: "Products" },
                list: ProductList,
                show: ProductShow,
                icon: <TagsOutlined />,
              },
              {
                name: "Payment",
                options: { route: "payment", label: "Payment" },
                list: PaymentList,
                show: PaymentList,
                icon: <DollarCircleOutlined />,
              },
              {
                name: "Assign",
                options: { route: "assign", label: "Assign" },
                list: AssignAgent,
                show: AssignAgent,
                // options: { route: "assign", label: "Assign" },
                icon: <UsergroupAddOutlined />,
              },
              {
                name: "My Orders",
                options: { route: "orders", label: "My Orders" },
                list: MyOrders,
                show: MyOrders,
                // options: { route: "assign", label: "Assign" },
                icon: <DollarCircleOutlined />,
              },
              {
                name: "My Bookings",
                options: { route: "bookings", label: "My Bookings" },
                list: Bookings,
                show: Bookings,
                // options: { route: "assign", label: "Assign" },
                icon: <DollarCircleOutlined />,
              },
              {
                name: "My Orders",
                options: { route: "orders", label: "My Orders" },
                list: MyOrders,
                show: MyOrders,
                // options: { route: "assign", label: "Assign" },
                icon: <DollarCircleOutlined />,
              },
              {
                name: "Settings",
                icon: <Settings />,
              },
              {
                name: "Admin",
                icon: <Settings />,
              },

              {
                parentName: "Settings",
                name: "Basic Information",
                options: { route: "company-info", label: "Basic Information" },
                list: PostList,
                edit: CompanyInfoEdit,
                icon: <BasicInfoIcon />,
              },
              {
                parentName: "Settings",
                name: "CRM Settings",
                options: {
                  route: "company-info-crm",
                  label: "CRM Information",
                },
                list: PostList,
                edit: CompanyInfoCRMEdit,
                icon: <CRMIcon />,
              },
              {
                parentName: "Settings",
                name: "Basic Information",
                options: { route: "company-info", label: "Basic Information" },
                list: PostList,
                edit: CompanyInfoEdit,
                icon: <BasicInfoIcon />,
              },
              {
                parentName: "Settings",
                name: "CRM Settings",
                options: {
                  route: "company-info-crm",
                  label: "CRM Information",
                },
                list: PostList,
                edit: CompanyInfoCRMEdit,
                icon: <CRMIcon />,
              },
            ]
          : authService.isMarketPlaceAdmin()
          ? [
              {
                name: "Inbox",
                options: { route: "inbox", label: "Inbox" },
                list: InboxList,
                show: InboxList,
                icon: <ChatIcon />,
              },
              {
                name: "Settings",
                icon: <Settings />,
              },
              {
                name: "Admin",
                icon: <Settings />,
              },

              {
                parentName: "Settings",
                name: "Basic Information",
                options: { route: "company-info", label: "Basic Information" },
                list: PostList,
                edit: CompanyInfoEdit,
                icon: <BasicInfoIcon />,
              },
              {
                parentName: "Settings",
                name: "CRM Settings",
                options: {
                  route: "company-info-crm",
                  label: "CRM Information",
                },
                list: PostList,
                edit: CompanyInfoCRMEdit,
                icon: <CRMIcon />,
              },
              {
                parentName: "Settings",
                name: "Basic Information",
                options: { route: "company-info", label: "Basic Information" },
                list: PostList,
                edit: CompanyInfoEdit,
                icon: <BasicInfoIcon />,
              },
              {
                parentName: "Settings",
                name: "CRM Settings",
                options: {
                  route: "company-info-crm",
                  label: "CRM Information",
                },
                list: PostList,
                edit: CompanyInfoCRMEdit,
                icon: <CRMIcon />,
              },
            ]
          : [
              {
                name: "Inbox",
                options: { route: "inbox", label: "Inbox" },
                list: InboxList,
                show: InboxList,
                icon: <ChatIcon />,
              },
              {
                name: "Assign",
                options: { route: "assign", label: "Assign" },
                list: AssignAgent,
                show: AssignAgent,
                // options: { route: "assign", label: "Assign" },
                icon: <UsergroupAddOutlined />,
              },
              {
                name: "My Orders",
                options: { route: "orders", label: "My Orders" },
                list: MyOrders,
                show: MyOrders,
                // options: { route: "assign", label: "Assign" },
                icon: <DollarCircleOutlined />,
              },
              {
                name: "My Bookings",
                options: { route: "bookings", label: "My Bookings" },
                list: MyOrders,
                show: MyOrders,
                // options: { route: "assign", label: "Assign" },
                icon: <DollarCircleOutlined />,
              },
              {
                name: "Settings",
                icon: <Settings />,
              },
              {
                name: "Admin",
                icon: <Settings />,
              },

              {
                parentName: "Settings",
                name: "Basic Information",
                options: { route: "company-info", label: "Basic Information" },
                list: PostList,
                edit: CompanyInfoEdit,
                icon: <BasicInfoIcon />,
              },
              {
                parentName: "Settings",
                name: "CRM Settings",
                options: {
                  route: "company-info-crm",
                  label: "CRM Information",
                },
                list: PostList,
                edit: CompanyInfoCRMEdit,
                icon: <CRMIcon />,
              },
              {
                parentName: "Settings",
                name: "Basic Information",
                options: { route: "company-info", label: "Basic Information" },
                list: PostList,
                edit: CompanyInfoEdit,
                icon: <BasicInfoIcon />,
              },
              {
                parentName: "Settings",
                name: "CRM Settings",
                options: {
                  route: "company-info-crm",
                  label: "CRM Information",
                },
                list: PostList,
                edit: CompanyInfoCRMEdit,
                icon: <CRMIcon />,
              },
            ]
      }
      Title={({ collapsed }) => (
        <Link to="/">
          {collapsed ? (
            <img
              src="/assets/logo1-32.svg"
              alt="Benam"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "12px 24px",
              }}
            />
          ) : (
            <img
              src="/assets/auc.png"
              alt="Benam"
              style={{
                width: "200px",
                padding: "12px 24px",
              }}
            />
          )}
        </Link>
      )}
      notificationProvider={notificationProvider}
      LoginPage={Login}
      Layout={Layout}
      catchAll={<ErrorComponent />}
    />
  );
};

export default App;
