const getLeadStatusData =  () => {
 
    const leadStatus =   [
        {
          label: "All",
          value: "ALL",
        },
        {
          label: "NEW",
          value: "NEW",
        },
        {
          label: "ASSIGNED",
          value: "ASSIGNED",
        },
        {
          label: "Cold",
          value: "Cold",
        },
        {
          label: "Warm",
          value: "Warm",
        },
        {
          label: "Hot",
          value: "Hot",
        },
        {
          label: "ON HOLD",
          value: "ON_HOLD",
        },
        {
          label: "TEST DRIVE SCHEDULED",
          value: "TEST_DRIVE_SCHEDULED",
        },
        {
          label: "DROPPED",
          value: "DROPPED",
        },
        {
          label: "DUPLICATE",
          value: "DUPLICATE",
        },
        {
          label: "TEST DRIVE DONE",
          value: "TEST_DRIVE_DONE",
        },
        {
          label: "SOLD",
          value: "SOLD",
        },
        {
          label: "EXTERNAL",
          value: "EXTERNAL",
        },{

          label: "FOLLOW_UP",
          value: "FOLLOW_UP",
        }
      ]
    return leadStatus;
  };

const getCallStatusData = () =>{
    const callstatuses =  [
        {
          label: "Connected",
          value: "Connected",
        },
        {
          label: "Busy",
          value: "Busy",
        },
        {
          label: "Ringing Not Responding",
          value: "Ringing_Not_Responding",
        },
        {
          label: "Wrong Number",
          value: "Wrong_Number",
        },
        {
          label: "Invalid Number",
          value: "Invalid_Number",
        },
        {
          label: "Number Not Available",
          value: "Number_Not_Available",
        },
        {
          label: "Switched Off",
          value: "Switched_Off",
        },
        {
          label: "Hung Up",
          value: "Hung_Up",
        },
        {
          label: "Chat On WhatsApp",
          value: "Chat_On_WhatsApp",
        },
        {
          label: "Other",
          value: "Other",
        },
      ]
      return callstatuses;
}  

const getDropReasons =() =>
{
    const dropReasons =    [
        {
          label: "Preferred Car Not Available",
          value: "Preferred Car Not Available",
        },
        {
          label: "High Price",
          value: "High Price",
        },
        {
          label: "Sold Out",
          value: "Sold Out",
        },
        {
          label: "Bought Another Car",
          value: "Bought Another Car",
        },
        {
          label: "Test Drive Location Far Away",
          value: "Test Drive Location Far Away",
        },
        {
          label: "Personal Emergency",
          value: "Personal Emergency",
        },
        {
          label: "Exploring Not Buying",
          value: "Exploring Not Buying",
        },

        {
          label: "Taxi Number Car Needed",
          value: "Taxi Number Car Needed",
        },
        {
          label: "Not Interested in Dealer Cars",
          value: "Not Interested in Dealer Cars",
        },
        {
          label: "Delaying Without Reason",
          value: "Delaying Without Reason",
        },
        {
          label: "Non Responding (>5 Follow-ups)",
          value: "Non Responding (>5 Follow-ups)",
        },
        {
          label: "Want To Sell Car",
          value: "Want To Sell Car",
        },
        {
          label: "Outside Lead",
          value: "Outside Lead",
        },
        {
          label: "Other",
          value: "Other",
        },
      ]

      return dropReasons;
}


export default {
    getLeadStatusData, getCallStatusData, getDropReasons
}