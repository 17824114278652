import { Avatar, Show, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import companyService from "services/companyService";
import { notifySuccess } from "../../components/Notification";
const { Title, Text } = Typography;
export const AssignAgent: React.FC<IResourceComponentsProps> = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any>([]);
  const [value, setValue] = useState<string>("");
  const [agentEmail, setAgentEmail] = useState<string>("");
  const [agentPhoneNumber, setPhoneNumber] = useState<string>("");
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [disabledSearch, setDisabledSearch] = useState(false);
  const getUserRoles = () => {
    const roles = localStorage.getItem("roles");
    if (roles?.includes("COMPANY_ADMIN") || roles?.includes("COMPANY_USER")) {
      setDisabledSearch(true);
      const companyId = localStorage.getItem("companyId");
      setSelectedCompanyId(companyId || "");
    }
  };
  useEffect(() => {
    getUserRoles();
  }, []);
  const getCompanies = async (searchStr: string) => {
    try {
      const res: any = await companyService.companies(searchStr);
      setCompanies(res[0].companies);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // console.log(error.response)
    }
  };
  const handleSubmit = async () => {
    let companyId;
    if (disabledSearch) {
      companyId = selectedCompanyId;
    } else {
      const comp = companies.filter((sn: any) => sn.companyName === value);
      companyId = comp[0].companyId;
    }
    try {
      const [res, status] = await companyService.assignAgent(
        agentEmail,
        companyId
      );
      setAgentEmail("");
      notifySuccess("User assigned successfully");
    } catch (error: any) {
      // console.log(error)
    }
  };

  const handlePhoneNumberSubmit = async () => {
    let companyId;
    if (disabledSearch) {
      companyId = selectedCompanyId;
    } else {
      const comp = companies.filter((sn: any) => sn.companyName === value);
      companyId = comp[0].companyId;
    }
    try {
      const [res, status] = await companyService.assignAgentPhoneNumber(
        agentPhoneNumber,
        companyId
      );
      setAgentEmail("");
      notifySuccess("User assigned successfully");
    } catch (error: any) {
      // console.log(error)
    }
  };

  const onSuggestionsFetchRequested = ({ value }: { value: any }) => {
    getCompanies(value);
  };

  const onSuggestionsClearRequested = () => {
    // setCompanies([])
  };
  const getSuggestionValue = (suggestion: any) => suggestion.companyName;
  const renderSuggestion = (suggestion: any) => (
    <div className="autocomplete-menu">
      <Avatar size={32} src={suggestion.imageUrl} />
      <Text style={{ marginLeft: "16px" }}>{suggestion.companyName}</Text>
      <Text style={{ marginLeft: "16px" }}>{suggestion.addressInfo?.city}</Text>
    </div>
  );
  const handleChange = (event: any, { newValue }: { newValue: any }) => {
    setValue(newValue);
  };
  return (
    <Show
      title="Assign Agent"
      isLoading={isLoading}
      headerButtons={() => <></>}
    >
      <ToastContainer />
      <div className="form">
        <div className="form__fields">
          <div className="form__autocomplete">
            {/* <Autocomplete
                            getItemValue={(item:any) => item.companyName}
                            items={companies}
                            renderItem={(item:any, isHighlighted:boolean) =>
                                <div className="autocomplete-menu" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                    <Avatar size={32} src={item.imageUrl}  />
                                    <Text style={{ marginLeft: "16px" }}>{item.companyName}</Text>
                                </div>
                            }
                            inputProps={{ placeholder: 'Select Company' }}
                            value={value}
                            onChange={(e:any,val:any) => setValue(val)}
                            onSelect={(val:any) => setValue(val)}
                        /> */}
            {!disabledSearch && (
              <Autosuggest
                suggestions={companies}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                  placeholder: "Search Company",
                  value,
                  onChange: handleChange,
                }}
              />
            )}
          </div>
          <div>
            <input
              className="form__field"
              type={"email"}
              value={agentEmail}
              placeholder="Enter Agent's Email ID"
              onChange={(e) => setAgentEmail(e.target.value)}
            />
            <button className="form__btn" onClick={() => handleSubmit()}>
              Assign email
            </button>
            {/* <input className="form__field" type={"text"} value={agentPhoneNumber} placeholder="Enter Agent's Phone Number" onChange={(e)=> setPhoneNumber(e.target.value)} /> */}
          </div>
          <div>
            {/* <input className="form__field" type={"email"} value={agentEmail} placeholder="Enter Agent's Email ID" onChange={(e)=> setAgentEmail(e.target.value)} /> */}
            <input
              className="form__field"
              type={"text"}
              value={agentPhoneNumber}
              placeholder="Enter Agent's Phone Number"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <button
              className="form__btn"
              onClick={() => handlePhoneNumberSubmit()}
            >
              Assign number
            </button>
          </div>
          {!disabledSearch && (
            <button
              style={{ marginLeft: "0" }}
              className="form__btn"
              onClick={() => {
                const comp = companies.filter(
                  (sn: any) => sn.companyName === value
                );
                var companyId = comp[0].companyId;
                navigate(`/Settings/company-info/edit/${companyId}`);
              }}
            >
              Edit
            </button>
          )}
        </div>
      </div>
    </Show>
  );
};
