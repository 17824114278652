import { defaultConfig } from "config";
import { ICompany, IUserCompany } from "interfaces";
import authService from "./authService";
//import {Axios, AxiosRequestConfig} from "axios"
import Axios from "services/axios";

// const getreqheader:AxiosRequestConfig<any> = () =>{
//   const token = localStorage.getItem("token");
//   const request:AxiosRequestConfig<any> ={};
//         if (request.headers) {
//             request.headers["Authorization"] = `Bearer ${token}`;
//         } else {
//             request.headers = {
//                 Authorization: `Bearer ${token}`,
//             };
//         }
//         return request;
// }

interface InitPaymentData {
  amountInLowestDen: number;
  referenceId: string;
  currency: string;
  orderType: string;
  serviceDescription: string;
  companyId: string;
  userId: string;
}

async function generateMagicLink(email: string) {
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.generateMagicLink +
    `?email=${email}`;
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
}
let apiTimeout: any;
async function getMagicLinkStatus() {
  const mlRqId = localStorage.getItem("magiclink-requestId1");
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getMagicLinkStatus +
    `?requestId=${mlRqId}`;
  const response = await Axios.axiosInstance.get(url);
  if (response.data.status === "PENDING") {
    apiTimeout = setTimeout(getMagicLinkStatus, 5000);
  } else {
    clearTimeout(apiTimeout);
  }

  return [response.data, response.status];
}

async function getCompanyInfo(companyId?: string) {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;

  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getCompanyInfo +
    (companyId ? companyId : authService.getLoggedInUserCompanyId());
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
}

async function getCategories() {
  // const authHeaderStr = "Bearer " + localStorage.getItem("token")
  // Axios.axiosInstance.defaults.headers.common['Authorization'] = authHeaderStr

  const url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.getCategories;
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
}

async function getSubCategories(categoryId: string) {
  // const authHeaderStr = "Bearer " + localStorage.getItem("token")
  // Axios.axiosInstance.defaults.headers.common['Authorization'] = authHeaderStr

  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getSubCategories +
    categoryId;
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
}

async function updateCompanyInfo(
  comp: ICompany,
  companyId?: string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getCompanyInfo +
    (companyId ? companyId : authService.getLoggedInUserCompanyId());
  const response = await Axios.axiosInstance.put(url, comp);

  return [response.data, response.status];
}

async function getLinkedUsers(
  pageNumber: number,
  pageSize: number
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;

  const url =
    defaultConfig.urls.serviceBaseUrl +
    (authService.isSuperAdmin()
      ? defaultConfig.urls.getUserCompaniesAdmin +
        `?page=${pageNumber}` +
        `&pageSize=${pageSize}`
      : defaultConfig.urls.getUserCompaniesAdmin +
        "?companyId=" +
        authService.getLoggedInUserCompanyId() +
        `&page=${pageNumber}` +
        `&pageSize=${pageSize}`);

  let c = replaceParam(url, [authService.getLoggedInUserCompanyId()!]);
  const response = await Axios.axiosInstance.get(c);

  return [response.data, response.status];
}

async function getPosts(): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getPosts +
    `?postStatus=CREATED`;
  let c = replaceParam(url, [authService.getLoggedInUserCompanyId()!]);
  const response = await Axios.axiosInstance.get(c);
  console.log(response);
  return [response.data, response.status];
}

async function getFeebackPosts(): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getPosts +
    `?postStatus=CREATED` +
    `&postType=FEEDBACK`;

  let c = replaceParam(url, [authService.getLoggedInUserCompanyId()!]);
  const response = await Axios.axiosInstance.get(c);
  console.log(response);
  return [response.data, response.status];
}

const approvePost = async (
  postId: String,
  approve: Boolean,
  deeplink: String
) => {
  try {
    const authHeaderStr = "Bearer " + localStorage.getItem("token");
    Axios.axiosInstance.defaults.headers.common["Authorization"] =
      authHeaderStr;
    let url =
      defaultConfig.urls.serviceBaseUrl +
      defaultConfig.urls.approvePost +
      `/${postId}/` +
      "approve" +
      `?isApproved=${approve}`;
    if (deeplink != null && deeplink.trim() != "") {
      url = url + `&deeplink=${deeplink}`;
    }
    let c = replaceParam(url, [authService.getLoggedInUserCompanyId()!]);
    console.log(url);
    const response = await Axios.axiosInstance.post(c);

    return [response.data, response.status];
  } catch (e) {
    console.log(e);
    return ["", ""];
  }
};

async function companies(searchStr: string): Promise<ICompany[]> {
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getCompaniesInternal +
    `?searchStr=${searchStr}`;
  const response = await Axios.axiosInstance.get(url);
  return [response.data, response.status];
}
async function assignAgent(
  userEmail: string,
  companyId: string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.assignAgent +
    `/${userEmail}/assignCompany/${companyId} `;
  const response = await Axios.axiosInstance.put(url);
  return [response.data, response.status];
}

async function assignAgentPhoneNumber(
  phoneNumber: string,
  companyId: string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.assignAgentPhone +
    `/${phoneNumber}/assignCompany/${companyId} `;
  const response = await Axios.axiosInstance.put(url);
  return [response.data, response.status];
}

async function getMsgs(
  userId: string,
  companyId: string,
  page: number,
  pageSize: number
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.getUserMsgUrl;
  let c = replaceParam(url, [companyId, userId]);
  c = c + "?page=" + page + "&pageSize=" + pageSize;
  const response = await Axios.axiosInstance.get(c);

  return [response.data, response.status];
}

async function sendMsg(
  userId: string,
  companyId: string,
  content: string,
  messageType: string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.sendUserMsgUrl;
  let c = replaceParam(url, [companyId!, userId]);
  const response = await Axios.axiosInstance.post(c, {
    content: content,
    messageType,
  });

  return [response.data, response.status];
}

async function changeChatStatus(
  userId: string,
  companyId: string,
  status: string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.changeChatStatus +
    companyId +
    "/users/" +
    userId +
    "/update/" +
    status;
  // let c = replaceParam(url, [companyId!, userId])
  const response = await Axios.axiosInstance.post(url);

  return [response.data, response.status];
}

async function getStatusChats(
  companyId: string,
  callbackrequested: boolean,
  status: string,
  res: string[]
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  let responseFilterString = "";
  res.map((sn) => {
    responseFilterString = responseFilterString + "&" + sn + "=true";
  });
  const url =
    defaultConfig.urls.serviceBaseUrl +
    (authService.isSuperAdmin()
      ? defaultConfig.urls.getUserCompaniesAdmin +
        `?callbackRequested=${callbackrequested}`
      : defaultConfig.urls.getUserCompaniesAdmin +
        "?companyId=" +
        authService.getLoggedInUserCompanyId() +
        `&callbackRequested=${callbackrequested}`) +
    `&page=0` +
    `&pageSize=50` +
    (status != "ALL" ? `&status=${status}` : "") +
    responseFilterString;
  // let c = replaceParam(url, [companyId!, userId])
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
}
async function getAllOrders(): Promise<any[]> {
  const companyId = localStorage.getItem("companyId");
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getCompanyInfo +
    companyId +
    "/orders";
  // let c = replaceParam(url, [companyId!, userId])
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
}

const imageUploadService = async (file: File) => {
  const form = new FormData();
  form.append("file", file);
  const url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.imageUpload;

  const response = await Axios.axiosInstance.post(url, form, {
    headers: {
      "content-type": "multipart/form-data;",
    },
  });

  return [response.data, response.status];
};

async function requestCallback(companyId: string | null) {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.requestCallback +
    `/${companyId}/` +
    "requestACallBack" +
    "/userid@benam.me";

  const response = await Axios.axiosInstance.post(url);
  // console.log(response)
  return [response.data, response.status];
}

const callingStatus = async (
  callId: string | null,
  callStatus: string,
  userId: string,
  companyId: string
) => {
  // const companyId = localStorage.getItem("companyId")
  // const userId = localStorage.getItem("userId")
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.requestCallback +
    `/${companyId}/` +
    "users" +
    `/${userId}/` +
    "call";
  const body = {
    callId,
    callStatus,
  };
  const response = await Axios.axiosInstance.post(url, body);

  return [response.data, response.status];
};

const initialisePayment = async (initPaymentData: InitPaymentData) => {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.initPayment;
  const body = { ...initPaymentData };
  // console.log(body)
  const response = await Axios.axiosInstance.post(url, body);

  return [response.data, response.status];
};

const updatedOrder = async (companyId: string, orderId: string) => {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getCompanyInfo +
    `${companyId}/` +
    "orders" +
    `/${orderId}`;
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
};

//OTP routes
const generateOTP = async (phoneNumber: string) => {
  const url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.generateOtp;
  const body = {
    phoneNo: phoneNumber,
  };
  const response = await Axios.axiosInstance.post(url, body);
  return [response.data, response.status];
};

const resendOtp = async (phoneNumber: string) => {
  const url = defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.resendOtp;
  const body = {
    phoneNo: phoneNumber,
  };
  const response = await Axios.axiosInstance.post(url, body);
  return [response.data, response.status];
};

const verifyOtp = async (phoneNumber: string, otp: string) => {
  const url = defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.verifyOtp;
  const body = {
    phoneNo: phoneNumber,
    otp,
  };
  const response = await Axios.axiosInstance.post(url, body);
  return [response.data, response.status];
};

const getAllBookings = async (companyId: string | null) => {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;

  let url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getBookings +
    "?status=APPROVED";

  if (!authService.isSuperAdmin() && companyId != null) {
    url += `&companyId=${companyId}`;
  }

  console.log(url);
  const response = await Axios.axiosInstance.get(url);
  console.log(response.data);
  return [response.data, response.status];
};

const createPaymentRequest = async (data: any) => {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;

  let url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.createPaymentRequest;

  const response = await Axios.axiosInstance.post(url, data);
  return [response.data, response.status];
};

const getAssignToUsers = async (companyId: String): Promise<any> => {
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.getAssignToUsers +
    companyId +
    "/companyUsers";
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
};

export default {
  getMagicLinkStatus,
  generateMagicLink,
  companies,
  assignAgent,
  getCompanyInfo,
  updateCompanyInfo,
  getLinkedUsers,
  getMsgs,
  sendMsg,
  getCategories,
  getSubCategories,
  changeChatStatus,
  getStatusChats,
  imageUploadService,
  requestCallback,
  callingStatus,
  initialisePayment,
  updatedOrder,
  getAllOrders,
  getPosts,
  approvePost,
  getFeebackPosts,
  generateOTP,
  resendOtp,
  verifyOtp,
  assignAgentPhoneNumber,
  getAllBookings,
  createPaymentRequest,
  getAssignToUsers,
};

const replaceParam = (url: string, params: string[]) => {
  return url?.replace(/{(\d+)}/g, function (match, number) {
    return typeof params[number] != "undefined" ? params[number] : match;
  });
};
