import { defaultConfig } from "config";
import { Lead, Product } from "interfaces";
import { async } from "q";
import Axios from "services/axios";
import { replaceParam } from "utils/common";


async function getProducts(
  productStatus: string | null,
  page: number,
  pageSize: number,
  companyId?:string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  var url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.getProducts;

  url = url + "?page=" + page + "&pageSize=" + pageSize;
  if (productStatus) {
    url = url + "&productStatus=" + productStatus;
  }
  if(companyId)
  {
    url = url + "&companyId=" + companyId;
  }

  const response = await Axios.axiosInstance.get(url);

  return [response.data, response.status];
}
async function getProduct(
  productId?:string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  var url =
    defaultConfig.urls.serviceBaseUrl + defaultConfig.urls.getProduct;

    let c = replaceParam(url, [productId!]);


  const response = await Axios.axiosInstance.get(c);

  return [response.data, response.status];
}




async function saveProduct(product: Product, priceOverwrite:boolean|undefined): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.saveProduct
  let c = replaceParam(url, [product.companyId!]);

  console.log(priceOverwrite)
  if(priceOverwrite)
    c = c + "&overwritePrice=true";

  const response = await Axios.axiosInstance.put(c, product);

  return [response.data, response.status];
}

async function changeProductStatus(
  productId: string,
  isApproved: boolean,
  companyId: string
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.serviceBaseUrl +
    defaultConfig.urls.updateProductStatus + "?isApproved=" + isApproved

  let c = replaceParam(url, [companyId, productId]);
  const response = await Axios.axiosInstance.post(c);

  return [response.data, response.status];
}

export default {
  getProducts,
  changeProductStatus,
  saveProduct,
  getProduct
};



