import { useEffect, useState } from "react";
import {
    IResourceComponentsProps
} from "@pankod/refine-core";
import {
    Show,
    Typography,
    Avatar
} from "@pankod/refine-antd";
import { useNavigate } from 'react-router-dom';
import companyService from "services/companyService";
const { Title, Text } = Typography;
import Autosuggest from "react-autosuggest"
import { notifySuccess } from "../../components/Notification";
import { ToastContainer, toast } from 'react-toastify';
import dateFormat from "dateformat";
export const MyOrders: React.FC<IResourceComponentsProps> = () => {

    const [allorders, setAllOrders] = useState([])

    const getAllOrders = async()=>{
        const [response, status] =await companyService.getAllOrders()
        console.log(response)
        setAllOrders(response)
    }

    useEffect(()=>{
        getAllOrders()
    },[])

    return (
        <Show
            title="My Orders"
            // isLoading={isLoading}
            headerButtons={() => (
                <>
                </>
            )}
            
        >
            <ToastContainer/>
            <div>
                {
                    (allorders && allorders.length>0) ? allorders.map((item:any)=>(
                        <div className={"allOrdersWrp"}>
                            <div className="orderInfo" >
                                <p>OrderId : <span>{item.orderId}</span></p>
                                <p>UserId : <span>{item.userId}</span></p>
                                <p>Created At : <span>{dateFormat(new Date(item.createdAt! * 1000), "dd-mmm hh:MM TT")}</span></p>
                                {item.service?.title.length>0 && <p>Description: <span>{item.service?.title}</span></p>}
                            </div>
                            <div className="paymentWrp" style={{padding:"0"}} >
                                <div className="payamnt" ><img width="10px" src="/rupee.svg" />{item.amount/100}</div>
                                {item.status==="INPROGRESS" ?
                                    <div className="payinitbtn" >
                                        <p>Payment Requested</p>
                                        {/* <ReloadOutlined className="reloadicon" onClick={()=>getOrderByOrderId(item.metadata.order.orderId,item.messageId)} /> */}
                                    </div> :
                                    <div className="paysuccessbtn" >
                                        <p>Payment Recieved</p>
                                    </div>
                                }
                            </div>
                        
                    </div>
                    )):
                    <div>
                        <h1>
                            No orders placed yet.
                        </h1>
                    </div>
                }
            
            </div>
        </Show>
    );
};