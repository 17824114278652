import { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Button, Collapse, DatePicker, Select } from "@pankod/refine-antd";

import { CustomerInteraction, Lead } from "interfaces";
import allUsedCarsService from "services/allusedCarsService";

import authService from "services/authService";
import dateFormat from "dateformat";
interface LeadProps {
  lead: Lead | null;
  callback: any;
  show: boolean;
}

const SendWhatsappPopup = (props: LeadProps) => {
  var { lead, callback, show } = props;
  const Panel = Collapse.Panel;
  const [showin, setShow] = useState<boolean>(false);

  const [leadObj, setLead] = useState<Lead>();

  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setLead(lead!);
    setShow(show);
    setComment("");
  }, []);

  useEffect(() => {
  
  }, [JSON.stringify(lead)]);

  useEffect(() => {
    setLead(lead!);
    setShow(show);
    setComment("");
  }, [show]);


  function handleCancel(event: any) {
    callback();
    setShow(false);
  }
  async function handleCallback() {
    if (callback) {
      var msg: CustomerInteraction = { content:commentRef.current?.value!, createdBy:authService.getLoggedInUserId()!}
     // console.log(JSON.stringify(msg))
      await allUsedCarsService.sendCustomerMsg(leadObj?.leadId!,msg );
     commentRef.current!.value = "";
      callback(leadObj);
      setShow(false);
    }
  }
  const commentRef = useRef<HTMLTextAreaElement>(null);
  return (
    <div
      className="filterspopupWrp"
      style={{ display: showin ? undefined : "none" }}
    >
      <div className="filtersScrollArea">
      {lead && lead.customerInteractions && (
   <Collapse defaultActiveKey={["0"]}>
              <Panel   key={0} header={"Chats"}>
             
                {lead.customerInteractions &&
                lead.customerInteractions.map((interaction, indx) =>  (
                  <div className="chatcontainer__innerdiv" key={indx}>
                  <li className={interaction.incoming ? 'message left' : 'message right'}>
                
                  <p className="messageWrp" >{interaction?.content?.replace(/\n/g, "<br />")} </p> 
              {interaction.createdAt &&<h4>{dateFormat(new Date(interaction.createdAt! * 1000), "dd-mmm hh:MM TT")}</h4>}
              </li>
                
                </div>
                  ))}
            
              </Panel></Collapse>
            )}
   
        <div className="seperate-filter-wrp">
          <h4>Comments</h4>
          <div className="res-filter-wrp">
            <textarea
            rows={5}
                defaultValue={comment}
                ref={commentRef}
              style={{ width: "100%", color: "gray" }}
              id="input"
  
            />
          </div>
         
        </div>
  
      </div>
      <Button
        className="filtersApplybtn"
        onClick={() => {
          handleCallback();
        }}
      >
        Submit
      </Button>{" "}
      <Button className="filtersApplybtn" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  );
};

export default SendWhatsappPopup;
